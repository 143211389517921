import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const FeatureList = ({ features }) => {
  // Group features by category
  const groupedFeatures = features.reduce((acc, feature) => {
    const category = feature.category;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(feature);
    return acc;
  }, {});

  return (
    <div className="p-8 space-y-10">
      {Object.keys(groupedFeatures).map((category) => (
        <div key={category} className="bg-white border border-gray-200 rounded-lg shadow-lg p-6">
          <h3 className="text-2xl font-semibold text-gray-900 mb-5 border-b border-gray-300 pb-2">{category}</h3>
          <ul className="space-y-6">
            {groupedFeatures[category].map((feature) => (
              <li key={feature.id} className="flex items-start">
                <div className="text-green-500 text-2xl mr-4">
                  {feature.access ? <FaCheckCircle /> : <FaTimesCircle className="text-red-500" />}
                </div>
                <div>
                  <h4 className="text-lg font-medium text-gray-700">{feature.name}</h4>
                  <p className="text-gray-500">
                    {feature.access !== false ? feature.access : 'Ikke inkludert'}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FeatureList;