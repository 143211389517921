import React from 'react';
import { FaTrash, FaEdit } from 'react-icons/fa';
import Loader from '../Loader';

const CompanyUserTable = ({ users, loading = false, onDelete }) => (
  <table className="min-w-full bg-dark-bg rounded-lg shadow overflow-hidden">
    <thead className="bg-dark-hover text-dark-text">
      <tr>
        <th className="py-2 px-4 text-left">E-post</th>
        <th className="py-2 px-4 text-left">Navn</th>
        <th className="py-2 px-4 text-left">Telefon</th>
        <th className="py-2 px-4 text-left">Rolle</th>
        <th className="py-2 px-4 text-left">Handlinger</th>
      </tr>
    </thead>
    <tbody className="text-dark-text">
      {loading && <tr><td colSpan="5"><Loader /></td></tr>}
      {users.map((user) => (
        <tr key={user.id} className="border-b border-dark-border">
          <td className="py-2 px-4">{user.email}</td>
          <td className="py-2 px-4">{user.first_name} {user.last_name}</td>
          <td className="py-2 px-4">{user.contact_number}</td>
          <td className="py-2 px-4">{user.role}</td>
          <td className="py-2 px-4 flex space-x-2">
            <button className="text-blue-500 hover:text-blue-600 transition">
              <FaEdit />
            </button>
            <button
              onClick={() => onDelete(user.id)}
              className="text-red-500 hover:text-red-600 transition"
            >
              <FaTrash />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CompanyUserTable;