import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Header from '../../components/Header';
import Card from '../../components/admin/Card';

const AdminDashboard = () => {
  const [stats, setStats] = useState({});

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const [
          totalUsers,
          totalActiveUsers,
          totalInvoices,
          totalRevenue,
          totalExpenses,
          totalLicenses,
        ] = await Promise.all([
          axiosInstance.get('/api/admin-stats/total-users'),
          axiosInstance.get('/api/admin-stats/total-active-users'),
          axiosInstance.get('/api/admin-stats/total-invoices'),
          axiosInstance.get('/api/admin-stats/total-revenue'),
          axiosInstance.get('/api/admin-stats/total-expenses'),
          axiosInstance.get('/api/admin-stats/total-licenses'),
        ]);

        setStats({
          totalUsers: totalUsers.data.totalUsers,
          totalActiveUsers: totalActiveUsers.data.totalActiveUsers,
          totalInvoices: totalInvoices.data.totalInvoices,
          totalRevenue: totalRevenue.data.totalRevenue,
          totalExpenses: totalExpenses.data.totalExpenses,
          totalLicenses: totalLicenses.data.totalLicenses,
        });
      } catch (err) {
        console.error('Failed to fetch stats:', err);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="p-4">
      <Header text="Dashboard" theme="dark" />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <Card title="Total Users" value={stats.totalUsers} icon="users" />
        <Card title="Total Active Users" value={stats.totalActiveUsers} icon="users" />
        <Card title="Total Invoices" value={stats.totalInvoices} icon="invoices" />
        <Card title="Total Revenue" value={`${stats.totalRevenue} NOK`} icon="revenue" />
        <Card title="Total Expenses" value={`${stats.totalExpenses} NOK`} icon="expenses" />
        <Card title="Total Licenses" value={stats.totalLicenses} icon="licenses" />
      </div>
    </div>
  );
};

export default AdminDashboard;