import React from 'react';

const Pagination = ({ page, totalPages, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="mt-4 flex justify-center space-x-2">
      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`px-3 py-2 rounded ${page === number ? 'bg-medium-blue-custom text-white' : 'bg-gray-300 text-black'}`}
        >
          {number}
        </button>
      ))}
    </div>
  );
};

export default Pagination;