import React, { useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import UserContext from '../../contexts/UserContext';
import { FaFileExport } from 'react-icons/fa';
import Button from '../../components/Button'; // Import the Button component

const ExportCustomers = () => {
  const { user } = useContext(UserContext);

  const handleExport = async () => {
    try {
      const response = await axiosInstance.get(`/api/customers/export/${user.id}`);
      const customers = response.data;

      if (customers && customers.length > 0) {
        const filteredCustomers = customers.map(({ id, user_id, ...rest }) => rest); // Exclude id and user_id
        const csv = Papa.unparse(filteredCustomers);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'customers.csv');
      } else {
        console.error('No customers data to export.');
      }
    } catch (err) {
      console.error('Failed to export customers', err);
    }
  };

  return (
    <Button
      text="Export"
      icon={<FaFileExport />}
      onClick={handleExport}
      type="success"
    />
  );
};

export default ExportCustomers;