import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useParams } from 'react-router-dom';
import { FaEdit, FaPrint, FaPaperPlane, FaEnvelope, FaFileAlt } from 'react-icons/fa'; // Import FaFileAlt
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Loader from '../../components/Loader';
import { handlePrint, handleSend } from '../../utils/invoiceUtils';
import Button from '../../components/Button'; // Import Button component

const InvoiceDetails = () => {
  const { invoiceId } = useParams();
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sendingEmail, setSendingEmail] = useState(false); // State for send button loading
  const [creatingCreditNote, setCreatingCreditNote] = useState(false); // State for credit note creation

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axiosInstance.get(`/api/invoices/${user.company_id}/${invoiceId}`);
        setInvoice(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [invoiceId, user.company_id]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    try {
      await axiosInstance.put(`/api/invoices/${user.company_id}/${invoiceId}/status`, { status: newStatus });
      setInvoice((prev) => ({ ...prev, status: newStatus }));
      addNotification('Status oppdatert', { type: 'success' });
    } catch (err) {
      addNotification('Feil: Status ble ikke oppdatert', { type: 'error', persist: true });
      setError('Failed to update status');
    }
  };

  const handleSendClick = async () => {
    setSendingEmail(true);
    try {
      await handleSend(user.company_id, invoiceId, invoice.customer_email, addNotification);
    } catch (err) {
      console.error('Error sending email:', err);
    } finally {
      setSendingEmail(false);
    }
  };

  const handleCreateCreditNote = async () => {
    setCreatingCreditNote(true);
    try {
      const response = await axiosInstance.post(`/api/credit-notes/credit/${invoiceId}`, { company_id: user.company_id });
      addNotification('Kreditnota opprettet', { type: 'success' });
      // Optionally, navigate to the newly created credit note or update UI
      setInvoice((prev) => ({ ...prev, credited: true, status: 'credited' })); // Update invoice state to reflect the credit note creation
    } catch (err) {
      addNotification('Feil: Kunne ikke opprette kreditnota', { type: 'error', persist: true });
      console.error('Error creating credit note:', err);
    } finally {
      setCreatingCreditNote(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!invoice) {
    return <div>No invoice found.</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-off-white-custom shadow-md rounded-lg">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <h1 className="text-3xl font-bold text-dark-blue-custom mb-4 md:mb-0">Faktura</h1>
        <div className="flex items-center">
          <div className="mr-4">
            <p className="text-gray-blue-custom"><strong>Fakturanummer:</strong> {invoice.invoice_number}</p>
            <p className="text-gray-blue-custom"><strong>Dato:</strong> {new Date(invoice.invoice_date).toLocaleDateString('nb-NO')}</p>
            <p className="text-gray-blue-custom"><strong>Forfallsdato:</strong> {new Date(invoice.due_date).toLocaleDateString('nb-NO')}</p>
          </div>
          <div className="flex items-center">
            {invoice.email_sent ? (
              <FaEnvelope className="text-green-500 mr-2" title="Faktura sendt via e-post" />
            ) : (
              <FaEnvelope className="text-red-500 mr-2" title="Faktura ikke sendt" />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
        <h2 className="text-2xl font-bold text-dark-blue-custom mb-4 md:mb-0">Kundedetaljer</h2>
        <div className="space-x-2 flex items-center">
        {!invoice.credited && (
            <>
              <Button text="Endre" icon={<FaEdit />} linkTo={`/fakturaer/${invoiceId}/rediger`} type="secondary" />
              <Button text="Skriv Ut" icon={<FaPrint />} onClick={() => handlePrint(user.company_id, invoiceId, invoice.invoice_number, addNotification)} type="success" />
              <Button text={sendingEmail ? 'Sender...' : 'Send'} icon={!sendingEmail && <FaPaperPlane />} onClick={handleSendClick} type="primary" isLoading={sendingEmail} />
              <Button text={creatingCreditNote ? 'Oppretter...' : 'Opprett Kreditnota'} icon={!creatingCreditNote && <FaFileAlt />} onClick={handleCreateCreditNote} type="danger" isLoading={creatingCreditNote} />
            </>
          )}
        </div>
      </div>
      <div className="mb-8">
        <p className="text-gray-700"><strong>Navn:</strong> {invoice.customer_business_name}</p>
        <p className="text-gray-700"><strong>Kontaktperson:</strong> {invoice.customer_contact_name}</p>
        <p className="text-gray-700"><strong>Adresse:</strong> {invoice.customer_address}</p>
        <p className="text-gray-700"><strong>E-post:</strong> {invoice.customer_email}</p>
        <p className="text-gray-700"><strong>Telefon:</strong> {invoice.customer_phone}</p>
      </div>
      <div className="mb-8 hidden md:block">
        <h2 className="text-2xl font-bold text-dark-blue-custom mb-4">Produktdetaljer</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg overflow-hidden shadow">
            <thead className="bg-medium-blue-custom text-white">
              <tr>
                <th className="py-3 px-4 text-left">Produktnavn</th>
                <th className="py-3 px-4 text-left">Beskrivelse</th>
                <th className="py-3 px-4 text-right">Antall</th>
                <th className="py-3 px-4 text-right">Pris</th>
                <th className="py-3 px-4 text-right">Sum</th>
              </tr>
            </thead>
            <tbody>
              {invoice.products.map((product) => (
                <tr key={product.id} className="border-b">
                  <td className="py-2 px-4">{product.product_name}</td>
                  <td className="py-2 px-4">{product.product_description}</td>
                  <td className="py-2 px-4 text-right">{product.quantity}</td>
                  <td className="py-2 px-4 text-right">{product.price}</td>
                  <td className="py-2 px-4 text-right">{(product.price * product.quantity).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
          <p className="text-gray-700"><strong>Subtotal:</strong> {invoice.subtotal}</p>
          <p className="text-gray-700"><strong>MVA:</strong> {invoice.vat_total}</p>
          <p className="text-gray-700"><strong>Total:</strong> {invoice.total}</p>
        </div>
        <div className="w-full md:w-auto">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
            Status
          </label>
          <select
            id="status"
            value={invoice.status}
            onChange={handleStatusChange}
            className="w-full px-3 py-2 border rounded"
            disabled={invoice.credited} // Disable the status dropdown if credited
          >
            <option value="paid">Betalt</option>
            <option value="pending">Ubetalt</option>
            <option value="overdue">Forfalt</option>
            <option value="cancelled">Kansellert</option>
            <option value="credited">Kreditert</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;