import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Pagination from '../Pagination';
import UserContext from '../../contexts/UserContext';
import CompanyUserTable from './CompanyUserTable';
import AddUserForm from './AddUserForm';
import Loader from '../Loader';
import { FaPlusCircle } from 'react-icons/fa';

const CompanyUsers = () => {
  const { user } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [addUserMode, setAddUserMode] = useState(false);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    role: '',
    company_id: user.company_id,
  });

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/users', {
        params: { page, limit, company_id: user.company_id }
      });
      setUsers(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, limit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/api/users', newUser);
      setAddUserMode(false);
      fetchUsers();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axiosInstance.delete(`/api/users/${userId}`);
      fetchUsers();
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="p-4">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Bedrift Brukere</h2>
      <div className="space-y-4 bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold text-gray-800">Brukere</h3>
          <button
            onClick={() => setAddUserMode(true)}
            className="flex items-center bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition"
          >
            <FaPlusCircle className="mr-2" /> Legg til Bruker
          </button>
        </div>
        {addUserMode ? (
          <AddUserForm
            newUser={newUser}
            handleChange={handleChange}
            handleAddUser={handleAddUser}
            setAddUserMode={setAddUserMode}
          />
        ) : (
          <>
            <div className="overflow-x-auto">
              <CompanyUserTable users={users} loading={loading} onDelete={handleDeleteUser} />
            </div>
            <Pagination
              page={page}
              totalPages={Math.ceil(users.length / limit)}
              onPageChange={setPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyUsers;