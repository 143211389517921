import React from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader';

const CustomerCards = ({ customers, loading = false }) => (
  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:hidden">
    {loading && <Loader />}
    {customers.map((customer) => (
      <div key={customer.id} className="bg-white rounded-lg shadow p-4 border border-gray-200">
        <h3 className="text-xl font-bold text-medium-blue-custom hover:text-dark-blue-custom">
          <Link to={`/kunder/${customer.id}`}>{customer.name}</Link>
        </h3>
        <p><strong>Kontaktperson:</strong> {customer.contact_name}</p>
        <p><strong>Adresse:</strong> {customer.address}</p>
        <p><strong>E-post:</strong> {customer.email}</p>
        <p><strong>Telefon:</strong> {customer.phone}</p>
        <p><strong>Status:</strong> {customer.active ? 'Aktiv' : 'Inaktiv'}</p>
      </div>
    ))}
  </div>
);

export default CustomerCards;