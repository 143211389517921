import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ size }) => (
  <div className={`flex justify-center items-center ${size === 'small' ? 'py-0' : 'py-4'}`}>
    <div className={`loader ${size}`}></div>
  </div>
);

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
};

Loader.defaultProps = {
  size: 'large',
};

export default Loader;