// src/pages/Settings.js

import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Tabs from '../components/settings/Tabs';
import Profile from '../components/settings/Profile';
import Company from '../components/settings/Company';
import CompanyUsers from '../components/settings/CompanyUsers';
import EmailTemplate from '../components/settings/EmailTemplate';
import License from '../components/settings/License';
import TierComparisonTable from '../components/settings/TierComparisonTable';
import Integrations from '../components/settings/Integrations';
import Reports from '../components/settings/Reports';
import FinancialYearPage from '../components/settings/FinancialYearPage';

const Settings = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialTab = params.get('tab');

  const tabs = [
    { title: 'Profil', content: <Profile /> },
    { title: 'Firma', content: <Company /> },
    { title: 'Firma Brukere', content: <CompanyUsers /> },
    { title: 'Regnskapsår', content: <FinancialYearPage /> },
    { title: 'E-post Mal', content: <EmailTemplate /> },
    { title: 'Lisens', content: <License /> },
    { title: 'Prisplan', content: <TierComparisonTable /> },
    { title: 'Integrasjoner', content: <Integrations /> },
    { title: 'Rapporter', content: <Reports /> },
  ];

  return (
    <div className="p-4">
      <Header text="Innstillinger" />
      <Tabs tabs={tabs} initialTab={initialTab} />
    </div>
  );
};

export default Settings;