import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';

const Logout = () => {
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    setTimeout(() => {
      navigate('/');
    }, 2000); // Wait for 2 seconds before redirecting
  }, [logout, navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-blue-custom text-white">
      <div className="text-2xl font-bold mb-4">Logger ut...</div>
      <div className="loader"></div>
    </div>
  );
};

export default Logout;