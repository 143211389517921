import axiosInstance from './axiosInstance';

const verifyToken = async () => {
  try {
    const response = await axiosInstance.post('/api/auth/verify-token');
    return response.data;
  } catch (err) {
    console.error('Token verification failed:', err);
    return null;
  }
};

export default verifyToken;