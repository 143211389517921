import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import UserLicenseModal from '../../components/admin/UserLicenseModal';

const UserDetails = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addNotification } = useContext(NotificationContext);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosInstance.get(`/api/users/${id}`);
        setUser(response.data.user);
        setLicenses(response.data.licenses);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleAddLicense = async (licenseKey) => {
    try {
      await axiosInstance.post(`/api/user-licenses/activate`, {
        userId: id,
        licenseKey,
      });
      addNotification('License added successfully', { type: 'success' });
      // Refresh user details
      const response = await axiosInstance.get(`/api/users/${id}`);
      setUser(response.data.user);
      setLicenses(response.data.licenses);
    } catch (err) {
      addNotification('Error adding license', { type: 'error', persist: true });
    }
  };

  const handleGenerateLicense = async (tier, validityPeriodMonths) => {
    try {
      await axiosInstance.post(`/api/user-licenses/generate`, {
        tier,
        validityPeriodMonths,
        userId: id,
      });
      addNotification('License generated successfully', { type: 'success' });
      // Refresh user details
      const response = await axiosInstance.get(`/api/users/${id}`);
      setUser(response.data.user);
      setLicenses(response.data.licenses);
    } catch (err) {
      addNotification('Error generating license', { type: 'error', persist: true });
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <Header text={`User Details - ${user.email}`} theme="dark" />
      <div className="bg-dark-bg p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-dark-text">User Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6 text-dark-text">
          <div>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Business Name:</strong> {user.business_name}</p>
            <p><strong>Contact Name:</strong> {user.contact_name}</p>
            <p><strong>Address:</strong> {user.address}</p>
          </div>
          <div>
            <p><strong>Contact Number:</strong> {user.contact_number}</p>
            <p><strong>VAT Rate:</strong> {user.vat_rate}</p>
            <p><strong>Created At:</strong> {new Date(user.created_at).toLocaleDateString()}</p>
            <p><strong>Is Admin:</strong> {user.is_admin ? 'Yes' : 'No'}</p>
          </div>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-dark-text">Licenses</h2>
        {licenses.length > 0 ? (
          <div className="overflow-auto">
            <table className="min-w-full bg-dark-bg rounded-lg shadow overflow-hidden">
              <thead className="bg-dark-hover text-dark-text">
                <tr>
                  <th className="py-2 px-4 text-left">Key</th>
                  <th className="py-2 px-4 text-left">Tier</th>
                  <th className="py-2 px-4 text-left">Validity (months)</th>
                  <th className="py-2 px-4 text-left">Created At</th>
                  <th className="py-2 px-4 text-left">Activated</th>
                  <th className="py-2 px-4 text-left">Activated At</th>
                  <th className="py-2 px-4 text-left">Expires At</th>
                </tr>
              </thead>
              <tbody className="text-dark-text">
                {licenses.map((license) => (
                  <tr key={license.id} className="border-b border-dark-border hover:bg-dark-hover transition">
                    <td className="py-2 px-4">{license.key}</td>
                    <td className="py-2 px-4">{license.tier}</td>
                    <td className="py-2 px-4">{license.validity_period_months}</td>
                    <td className="py-2 px-4">{new Date(license.created_at).toLocaleDateString('nb-NO')}</td>
                    <td className="py-2 px-4">{license.is_active ? 'Yes' : 'No'}</td>
                    <td className="py-2 px-4">{license.activated_at ? new Date(license.activated_at).toLocaleDateString('nb-NO') : '-'}</td>
                    <td className="py-2 px-4">{license.expires_at ? new Date(license.expires_at).toLocaleDateString('nb-NO') : '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-red-500">No licenses found.</p>
        )}
        <div className="mt-6">
          <UserLicenseModal onAddLicense={handleAddLicense} onGenerateLicense={handleGenerateLicense} />
        </div>
      </div>
    </div>
  );
};

export default UserDetails;