import React, { useState, useEffect, useContext, useRef } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Subheader from '../Subheader';
import Loader from '../Loader';
import UpgradePrompt from '../modals/UpgradePrompt';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EmailTemplate = () => {
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const [template, setTemplate] = useState({ subject: '', content: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasAccess = user.tier !== 'Gratis';
  const hasPremiumAccess = user.tier === 'Premium';
  const [activeField, setActiveField] = useState(null);

  const subjectRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const response = await axiosInstance.get(`/api/email-templates/${user.company_id}`);
        setTemplate(response.data || { subject: '', content: '' });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTemplate();
  }, [user.id]);

  const handleChange = (name, value) => {
    setTemplate((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`/api/email-templates/${user.company_id}`, template);
      addNotification('E-post mal lagret.', { type: 'success' });
    } catch (err) {
      addNotification('Noe gikk galt. Prøv igjen.', { type: 'error' });
      setError(err.message);
    }
  };

  const insertKey = (key) => {
    if (activeField === 'subject') {
      setTemplate((prev) => ({ ...prev, subject: prev.subject + key }));
      subjectRef.current.focus();
    } else if (activeField === 'content') {
      const editor = contentRef.current.getEditor();
      editor.focus();
      const range = editor.getSelection(true);
      editor.insertText(range.index, key);
      editor.setSelection(range.index + key.length);
    }
  };

  const keys = [
    { key: '{{invoice_number}}', alias: 'Fakturanummer', category: 'Faktura' },
    { key: '{{invoice_date}}', alias: 'Fakturadato', category: 'Faktura' },
    { key: '{{due_date}}', alias: 'Forfallsdato', category: 'Faktura' },
    { key: '{{amount}}', alias: 'Beløp', category: 'Faktura' },
    { key: '{{customer_business_name}}', alias: 'Kunde Bedriftsnavn', category: 'Kunde' },
    { key: '{{customer_contact_name}}', alias: 'Kundekontakt Navn', category: 'Kunde' },
    { key: '{{customer_address}}', alias: 'Kunde Adresse', category: 'Kunde' },
    { key: '{{customer_email}}', alias: 'Kunde E-post', category: 'Kunde' },
    { key: '{{customer_phone}}', alias: 'Kunde Telefon', category: 'Kunde' },
    { key: '{{customer_post_number}}', alias: 'Kunde Postnummer', category: 'Kunde' },
    { key: '{{customer_post_location}}', alias: 'Kunde Poststed', category: 'Kunde' },
    { key: '{{company_name}}', alias: 'Firma Navn', category: 'Firma' },
    { key: '{{company_address}}', alias: 'Firma Adresse', category: 'Firma' },
    { key: '{{company_contact_number}}', alias: 'Firma Kontakt Nummer', category: 'Firma' },
    { key: '{{company_contact_email}}', alias: 'Firma Kontakt E-post', category: 'Firma' },
    { key: '{{company_account_number}}', alias: 'Firma Kontonummer', category: 'Firma' },
    { key: '{{company_post_number}}', alias: 'Firma Postnummer', category: 'Firma' },
    { key: '{{company_post_location}}', alias: 'Firma Poststed', category: 'Firma' },
    { key: '{{company_contact_first_name}}', alias: 'Firma Kontakt Fornavn', category: 'Kontaktperson' },
    { key: '{{company_contact_last_name}}', alias: 'Firma Kontakt Etternavn', category: 'Kontaktperson' },
    { key: '{{company_contact_phone}}', alias: 'Firma Kontakt Telefon', category: 'Kontaktperson' }
  ];

  if (!hasAccess) return <UpgradePrompt basicAccess featureName="e-post mal" />;
  if (loading) return <Loader />;
  if (error) return <div className="text-red-500 text-center mt-4">{`Error: ${error}`}</div>;

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-md">
      <Subheader text="E-post Mal" />
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-2">
            <Field
              label="Emne"
              id="subject"
              value={template.subject}
              onChange={(value) => handleChange('subject', value)}
              onFocus={() => setActiveField('subject')}
              ref={subjectRef}
            />
            <ContentEditor
              label="Innhold"
              value={template.content}
              onChange={(value) => handleChange('content', value)}
              onFocus={() => setActiveField('content')}
              ref={contentRef}
            />
          </div>
          {hasPremiumAccess && (
            <PlaceholderButtons keys={keys} insertKey={insertKey} />
            )}
            {!hasPremiumAccess && (
              <UpgradePrompt premiumAccess featureName="dynamiske plassholdere" />
            )}
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-600 text-white font-semibold px-6 py-2 rounded-md shadow hover:bg-blue-700 transition duration-200"
          >
            Lagre
          </button>
        </div>
      </form>
    </div>
  );
};

const Field = React.forwardRef(({ label, id, value, onChange, onFocus }, ref) => (
  <div className="mb-6">
    <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor={id}>
      {label}
    </label>
    <input
      id={id}
      name={id}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onFocus={onFocus}
      ref={ref}
      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200 focus:outline-none"
    />
  </div>
));

const ContentEditor = React.forwardRef(({ label, value, onChange, onFocus }, ref) => (
  <div className="mb-6">
    <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor="content">
      {label}
    </label>
    <ReactQuill
      id="content"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      ref={ref}
      className="bg-white border border-gray-300 rounded-md"
      modules={{
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
          ['clean'],
        ],
      }}
      formats={[
        'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'link', 'image',
      ]}
    />
  </div>
));

const PlaceholderButtons = ({ keys, insertKey }) => {
  const categories = [...new Set(keys.map(({ category }) => category))];

  return (
    <div className="col-span-1 p-4 bg-gray-100 rounded-md shadow-md">
      <label className="block text-gray-800 text-sm font-semibold mb-2">Plassholder</label>
      {categories.map((category) => (
        <div key={category}>
          <h3 className="text-md font-bold mb-2">{category}</h3>
          <div className="flex flex-wrap gap-2 mb-4">
            {keys.filter(key => key.category === category).map(({ key, alias }) => (
              <button
                key={key}
                type="button"
                onClick={() => insertKey(key)}
                className="bg-gray-200 px-3 py-1 rounded-md hover:bg-gray-300 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                {alias}
              </button>
            ))}
          </div>
        </div>
      ))}
      <p className="text-sm text-gray-600">Kan brukes i både emne og innhold</p>
    </div>
  );
};

export default EmailTemplate;