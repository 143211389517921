import React from 'react';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';

const AddUserForm = ({ newUser, handleChange, handleAddUser, setAddUserMode }) => {
  return (
    <form onSubmit={handleAddUser} className="space-y-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            E-post
          </label>
          <input
            id="email"
            name="email"
            type="email"
            value={newUser.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Passord
          </label>
          <input
            id="password"
            name="password"
            type="password"
            value={newUser.password}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
            Fornavn
          </label>
          <input
            id="first_name"
            name="first_name"
            type="text"
            value={newUser.first_name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
            Etternavn
          </label>
          <input
            id="last_name"
            name="last_name"
            type="text"
            value={newUser.last_name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
            Rolle
          </label>
          <input
            id="role"
            name="role"
            type="text"
            value={newUser.role}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          onClick={() => setAddUserMode(false)}
          className="flex items-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
        >
          <FaTrash className="mr-2" /> Avbryt
        </button>
        <button
          type="submit"
          className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          <FaPlusCircle className="mr-2" /> Legg til Bruker
        </button>
      </div>
    </form>
  );
};

export default AddUserForm;