import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import verifyToken from '../../utils/verifyToken';
import UserContext from '../../contexts/UserContext';
import Loader from '../Loader'; // Ensure you have a Loader component

const PrivateAdminRoute = ({ children }) => {
  const { login } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      const data = await verifyToken();
      if (data) {
        login(data);
        setIsValid(data.user.is_admin); // Ensure the user is an admin
      }
      setLoading(false);
    };
    checkToken();
  }, [login]);

  if (loading) {
    return <Loader />; // Show a loading spinner or placeholder content
  }

  return isValid ? children : <Navigate to="/" />;
};

export default PrivateAdminRoute;