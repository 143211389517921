import React, { useState } from 'react';
import vippsLogo from '../../images/integrations/vipps_logo.png'; // Replace with the actual path to your image
import creditCardLogo from '../../images/integrations/visa_mastercard_logo.png'; // Replace with the actual path to your image

const Integrations = () => {
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [fields, setFields] = useState({});

  const integrations = {
    vipps: {
      name: 'Vipps',
      fields: [
        { label: 'Client ID', name: 'client_id', type: 'text' },
        { label: 'Client Secret', name: 'client_secret', type: 'password' },
        { label: 'Subscription Key', name: 'subscription_key', type: 'text' },
      ],
      logo: vippsLogo,
    },
    creditCard: {
      name: 'Credit Card Payment',
      fields: [
        { label: 'API Key', name: 'api_key', type: 'text' },
        { label: 'API Secret', name: 'api_secret', type: 'password' },
        { label: 'Merchant ID', name: 'merchant_id', type: 'text' },
      ],
      logo: creditCardLogo,
    },
  };

  const handleIntegrationSelect = (integration) => {
    setSelectedIntegration(integration);
    setFields({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Selected Integration:', selectedIntegration);
    console.log('Fields:', fields);
    // Here you would handle the submission logic, e.g., sending the data to the backend.
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Integrasjoner</h2>
      <div className="flex flex-wrap gap-4 mb-4 justify-center">
        {Object.keys(integrations).map((key) => (
          <button
            key={key}
            onClick={() => handleIntegrationSelect(key)}
            className={`flex flex-col items-center p-4 border rounded-lg shadow-md transition ${selectedIntegration === key ? 'bg-gray-100' : 'bg-white'} hover:bg-gray-50`}
          >
            <img src={integrations[key].logo} alt={`${integrations[key].name} logo`} className="w-24 h-24 object-contain mb-2" />
            <span className="text-lg font-semibold">{integrations[key].name}</span>
          </button>
        ))}
      </div>
      {selectedIntegration && (
        <form onSubmit={handleSubmit} className="space-y-4">
          <h3 className="text-xl font-bold mb-2">Oppsett {integrations[selectedIntegration].name}</h3>
          {integrations[selectedIntegration].fields.map((field) => (
            <div key={field.name} className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={field.name}>
                {field.label}
              </label>
              <input
                id={field.name}
                name={field.name}
                type={field.type}
                value={fields[field.name] || ''}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          ))}
          <div className="flex justify-end">
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition">
              Lagre
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Integrations;