// src/components/Button.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

const buttonTypes = {
  primary: 'bg-blue-500 text-white hover:bg-blue-600',
  secondary: 'bg-gray-500 text-white hover:bg-gray-600',
  success: 'bg-green-500 text-white hover:bg-green-600',
  danger: 'bg-red-500 text-white hover:bg-red-600',
  warning: 'bg-yellow-500 text-white hover:bg-yellow-600',
};

const Button = ({ icon, text, linkTo, onClick, type = 'primary', isLoading = false }) => {
  const buttonClass = `px-4 py-2 rounded flex items-center justify-center space-x-2 transition-colors duration-200 ${buttonTypes[type]}`;

  const content = (
    <>
      {isLoading && <FaSpinner className="animate-spin" />}
      {icon && !isLoading && <span>{icon}</span>}
      {text && <span>{text}</span>}
    </>
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className={buttonClass}>
        {content}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className={buttonClass} disabled={isLoading}>
      {content}
    </button>
  );
};

export default Button;