import React, { useContext } from 'react';
import { FaEdit, FaPrint, FaPaperPlane } from 'react-icons/fa';
import UserContext from '../contexts/UserContext';
import { handlePrint, handleSend } from '../utils/invoiceUtils';

const MiniInvoiceActions = ({ invoiceId, invoiceNumber, customerEmail }) => {
  const { user } = useContext(UserContext);

  return (
    <div className="space-x-2 flex items-center">
      <button onClick={() => {/* Handle edit action */}} className="text-gray-600 hover:text-gray-900 transition">
        <FaEdit />
      </button>
      <button onClick={() => handlePrint(user.id, invoiceId, invoiceNumber)} className="text-gray-600 hover:text-gray-900 transition">
        <FaPrint />
      </button>
      <button onClick={() => {handleSend(user.id, invoiceId, customerEmail)}} className="text-gray-600 hover:text-gray-900 transition">
        <FaPaperPlane />
      </button>
    </div>
  );
};

export default MiniInvoiceActions;