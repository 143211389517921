import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Header from '../../components/Header';

const EditProduct = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    vat_applicable: true,
  });
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const { productId } = useParams();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axiosInstance.get(`/api/products/${user.company_id}/${productId}`);
        setFormData({
          name: response.data.name,
          description: response.data.description,
          price: response.data.price,
          vat_applicable: response.data.vat_applicable,
        });
      } catch (err) {
        setError(err.response?.data?.error || 'Failed to fetch product details');
      }
    };

    fetchProduct();
  }, [user.id, productId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/api/products/${user.company_id}/${productId}`, formData);
      addNotification('Produktet ble oppdatert', { type: 'success' });
      navigate('/produkter'); // Redirect to products page
    } catch (err) {
      addNotification('Feil: Produktet ble ikke oppdatert', { type: 'error', persist: true });
      setError(err.response?.data?.error || 'Failed to update product');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <Header text="Rediger produkt" />
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Navn</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Beskrivelse</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Pris</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">MVA</label>
            <input
              type="checkbox"
              name="vat_applicable"
              checked={formData.vat_applicable}
              onChange={handleChange}
              className="mr-2"
            />
          </div>
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="bg-medium-blue-custom text-white px-4 py-2 rounded hover:bg-dark-blue-custom transition"
          >
            Oppdater produkt
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProduct;