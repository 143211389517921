import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaClipboard } from 'react-icons/fa';

const LicenseTable = ({ licenses, loading, addNotification }) => {
  const [copiedKey, setCopiedKey] = useState(null);

  const handleCopy = (key) => {
    navigator.clipboard.writeText(key).then(() => {
      setCopiedKey(key);
      addNotification('Key copied to clipboard', { type: 'success' });
      setTimeout(() => setCopiedKey(null), 2000);
    });
  };

  return (
    <table className="min-w-full bg-dark-bg rounded-lg shadow overflow-hidden">
      <thead className="bg-dark-hover text-dark-text">
        <tr>
          <th className="py-2 px-4 text-left">Key</th>
          <th className="py-2 px-4 text-left">Tier</th>
          <th className="py-2 px-4 text-left">Validity (months)</th>
          <th className="py-2 px-4 text-left">Created At</th>
          <th className="py-2 px-4 text-left">Activated</th>
          <th className="py-2 px-4 text-left">User ID</th>
          <th className="py-2 px-4 text-left">Activated At</th>
          <th className="py-2 px-4 text-left">Expires At</th>
        </tr>
      </thead>
      <tbody className="text-dark-text">
        {loading && (
          <tr>
            <td colSpan="8" className="py-4 text-center">Loading...</td>
          </tr>
        )}
        {!loading && licenses.length === 0 && (
          <tr>
            <td colSpan="8" className="py-4 text-center">No licenses found.</td>
          </tr>
        )}
        {licenses.map((license) => (
          <tr key={license.id} className="border-b border-dark-border">
            <td
              className="py-2 px-4 cursor-pointer text-medium-blue-custom hover:text-dark-blue-custom transition flex items-center space-x-2"
              onClick={() => handleCopy(license.key)}
              title={copiedKey === license.key ? "Copied!" : "Click to copy"}
            >
              <span>{license.key}</span>
              <FaClipboard />
            </td>
            <td className="py-2 px-4">{license.tier}</td>
            <td className="py-2 px-4">{license.validity_period_months}</td>
            <td className="py-2 px-4">{new Date(license.created_at).toLocaleDateString('nb-NO')}</td>
            <td className="py-2 px-4">{license.is_activated ? 'Yes' : 'No'}</td>
            <td className="py-2 px-4">
              <Link to={`/admin/users/${license.user_id}`}>{license.user_id || '-'}</Link>
            </td>
            <td className="py-2 px-4">{license.activated_at ? new Date(license.activated_at).toLocaleDateString('nb-NO') : '-'}</td>
            <td className="py-2 px-4">{license.expires_at ? new Date(license.expires_at).toLocaleDateString('nb-NO') : '-'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LicenseTable;