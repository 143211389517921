import React from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader';

const UserTable = ({ users, loading = false }) => (
  <table className="min-w-full bg-dark-bg rounded-lg shadow overflow-hidden">
    <thead className="bg-dark-hover text-dark-text">
      <tr>
        <th className="py-2 px-4 text-left">E-post</th>
        <th className="py-2 px-4 text-left">Bedriftsnavn</th>
        <th className="py-2 px-4 text-left">Kontaktperson</th>
        <th className="py-2 px-4 text-left">Organisasjonsnummer</th>
        <th className="py-2 px-4 text-left">Adresse</th>
        <th className="py-2 px-4 text-left">Telefon</th>
        <th className="py-2 px-4 text-left">Status</th>
      </tr>
    </thead>
    <tbody className="text-dark-text">
      {loading && <Loader />}
      {users.map((user) => (
        <tr key={user.id} className="border-b border-dark-border">
          <td className="py-2 px-4"><Link to={`/admin/users/${user.id}`} className="text-medium-blue-custom hover:text-dark-blue-custom">{user.email}</Link></td>
          <td className="py-2 px-4">{user.business_name}</td>
          <td className="py-2 px-4">{user.contact_name}</td>
          <td className="py-2 px-4">{user.org_number}</td>
          <td className="py-2 px-4">{user.address}</td>
          <td className="py-2 px-4">{user.contact_number}</td>
          <td className="py-2 px-4">{user.is_active ? 'Aktiv' : 'Inaktiv'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default UserTable;