import React, { useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Papa from 'papaparse';
import UserContext from '../../contexts/UserContext';
import { FaFileImport } from 'react-icons/fa';
import Button from '../../components/Button'; // Import the Button component

const ImportCustomers = ({ onImport }) => {
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError('Please select a file to import');
      return;
    }

    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        try {
          const customers = results.data.filter(customer => customer.name); // Filter out rows without a name
          await axiosInstance.post(`/api/customers/import/${user.id}`, { customers });
          onImport();
        } catch (err) {
          setError('Failed to import customers');
        }
      },
      error: () => {
        setError('Failed to parse the CSV file');
      },
    });
  };

  return (
    <div className="relative">
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      <Button
        text="Import"
        icon={<FaFileImport />}
        type="primary"
      />
      {error && <div className="text-red-500 mt-2">{error}</div>}
    </div>
  );
};

export default ImportCustomers;
