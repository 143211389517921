import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const NoData = ({ text }) => {
  return (
    <div className="flex flex-col justify-center items-center h-full">
      <FaInfoCircle className="text-5xl text-gray-400 mb-4" />
      <p className="text-2xl text-gray-500">{text}</p>
    </div>
  );
};

export default NoData;