import React, { useEffect, useState, useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import verifyToken from '../utils/verifyToken';
import UserContext from '../contexts/UserContext';
import Loader from '../components/Loader'; // You can create a simple loader component if you don't have one

const PrivateRoute = ({ children }) => {
  const { login } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      const data = await verifyToken();
      if (data) {
        login(data);
        setIsValid(true);
      }
      setLoading(false);
    };
    checkToken();
  }, [login]);

  if (loading) {
    return <Loader />; // You can show a loading spinner or some placeholder content here
  }

  return isValid ? children : <Navigate to="/" />;
};

export default PrivateRoute;