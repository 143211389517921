import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Select, { components } from 'react-select';
import { Tooltip } from 'react-tooltip';
import { FaTimes, FaArrowDown, FaArrowUp } from 'react-icons/fa';

const AddInvoice = () => {
  const { customerId } = useParams();
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [invoiceProducts, setInvoiceProducts] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [references, setReferences] = useState({ your: '', our: '', delivery: '', reference: '' });
  const [dates, setDates] = useState({
    invoice: new Date().toISOString().split('T')[0],
    due: new Date().toISOString().split('T')[0],
    start: new Date().toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    if (customerId) {
      setSelectedCustomer({ value: parseInt(customerId), label: '' });
    }

    const fetchData = async () => {
      try {
        const [customersResponse, productsResponse, latestInvoiceNumberResponse] = await Promise.all([
          axiosInstance.get(`/api/customers/${user.company_id}`),
          axiosInstance.get(`/api/products/${user.company_id}`),
          axiosInstance.get(`/api/invoices/${user.company_id}/latest-invoice-number`),
        ]);
        setCustomers(customersResponse.data);
        setProducts(productsResponse.data.products);
        setInvoiceNumber(parseInt(latestInvoiceNumberResponse.data.latestInvoiceNumber) + 1);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user.id, customerId]);

  const handleAddProduct = (product) => setInvoiceProducts([...invoiceProducts, { ...product, quantity: 1 }]);

  const handleProductChange = (index, key, value) => {
    setInvoiceProducts(invoiceProducts.map((product, i) => (i === index ? { ...product, [key]: value } : product)));
  };

  const calculateTotals = () => {
    const subtotal = invoiceProducts.reduce((sum, product) => sum + (parseFloat(product.price) || 0) * (parseFloat(product.quantity) || 0), 0);
    const vatRate = parseFloat(user.vat_rate) / 100 || 0;
    const vatTotal = invoiceProducts.reduce(
      (sum, product) => sum + ((parseFloat(product.vat_applicable) ? (parseFloat(product.price) || 0) * (parseFloat(product.quantity) || 0) * vatRate : 0)),
      0
    );
    return { subtotal, vatTotal, total: subtotal + vatTotal };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { subtotal, vatTotal, total } = calculateTotals();

    try {
      const invoiceResponse = await axiosInstance.post(`/api/invoices/${user.company_id}`, {
        invoice_number: invoiceNumber,
        customer_id: selectedCustomer?.value,
        invoice_date: dates.invoice,
        due_date: dates.due,
        start_date: dates.start,
        end_date: dates.end,
        status: 'pending',
        your_reference: references.your,
        our_reference: references.our,
        delivery_reference: references.delivery,
        reference: references.reference,
        subtotal,
        vat_total: vatTotal,
        total,
      });
      const invoiceId = invoiceResponse.data.id;
      await Promise.all(
        invoiceProducts.map((product) =>
          axiosInstance.post(`/api/invoice-products/${user.company_id}`, {
            invoice_id: invoiceId,
            product_id: product.id,
            quantity: product.quantity,
            price: product.price,
          })
        )
      );
      addNotification('Faktura opprettet', { type: 'success' });
      navigate(`/fakturaer/${invoiceId}`);
    } catch (err) {
      addNotification('Feil ved oppretting av faktura', { type: 'error', persist: true });
      setError(err.message);
    }
  };

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }));

  const CustomOption = (props) => {
    const customer = customers.find((c) => c.id === props.data.value);
    return (
      <components.Option {...props}>
        <div>
          {props.data.label}
          <br />
          <small>{customer?.contact_name}</small>
          <br />
          <small>{customer?.address}</small>
        </div>
      </components.Option>
    );
  };

  const handleRemoveProduct = (index) => setInvoiceProducts(invoiceProducts.filter((_, i) => i !== index));
  const handleQuantityChange = (index, delta) => {
    setInvoiceProducts(invoiceProducts.map((product, i) =>
      i === index ? { ...product, quantity: Math.max(1, product.quantity + delta) } : product
    ));
  };

  const handleDateChange = (name, date) => {
    setDates({ ...dates, [name]: date });
    if (name === 'invoice') {
      const due = new Date(date);
      due.setDate(due.getDate() + user.due_date_offset);
      setDates({ ...dates, invoice: date, due: due.toISOString().split('T')[0] });
    }
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  const { subtotal, vatTotal, total } = calculateTotals();

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <Header text="Opprett Faktura" />
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block text-gray-700">Velg Kunde</label>
          <Select
            options={customerOptions}
            onChange={setSelectedCustomer}
            value={selectedCustomer}
            className="w-full px-3 py-2 border rounded"
            placeholder="Velg Kunde"
            isSearchable
            components={{ Option: CustomOption }}
          />
        </div>
        {selectedCustomer && (
          <div className="mb-6 p-4 border rounded bg-gray-100">
            <h2 className="text-lg font-bold mb-2">{selectedCustomer.label}</h2>
            {customers
              .filter((customer) => customer.id === selectedCustomer.value)
              .map((customer) => (
                <div key={customer.id}>
                  <p>
                    <strong>Kontaktperson:</strong> {customer.contact_name}
                  </p>
                  <p>
                    <strong>E-post:</strong> {customer.email}
                  </p>
                  <p>
                    <strong>Telefon:</strong> {customer.phone}
                  </p>
                  <p>
                    <strong>Organisasjonsnummer:</strong> {customer.org_number}
                  </p>
                  <p>
                    <strong>Adresse:</strong> {customer.address}
                  </p>
                  <p>
                    <strong>Postnummer:</strong> {customer.post_number}
                  </p>
                  <p>
                    <strong>Poststed:</strong> {customer.post_location}
                  </p>
                </div>
              ))}
          </div>
        )}
        <div className="mb-6">
          <label className="block text-gray-700">Fakturanummer</label>
          <input
            type="number"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Referanser</label>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {Object.keys(references).map((key) => (
              <input
                key={key}
                type="text"
                value={references[key]}
                onChange={(e) => setReferences({ ...references, [key]: e.target.value })}
                className="w-full px-3 py-2 border rounded"
                placeholder={`${key.charAt(0).toUpperCase() + key.slice(1)} referanse`}
              />
            ))}
          </div>
        </div>
        <div className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.keys(dates).map((key) => (
              <div key={key}>
                <label className="block text-gray-700 mb-1">{`${key.charAt(0).toUpperCase() + key.slice(1)}dato`}</label>
                <input
                  type="date"
                  value={dates[key]}
                  onChange={(e) => handleDateChange(key, e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700">Produkter</label>
          <div className="flex flex-wrap">
            {products.map((product) => (
              <button
                key={product.id}
                type="button"
                onClick={() => handleAddProduct(product)}
                className="px-4 py-2 border rounded m-1"
                data-tooltip-id={`product-tooltip-${product.id}`}
              >
                {product.name}
              </button>
            ))}
          </div>
          {products.map((product) => (
            <Tooltip key={product.id} id={`product-tooltip-${product.id}`} place="top" effect="solid">
              <div>
                <strong>{product.name}</strong>
                <br />
                {product.description}
                <br />
                Pris: {product.price} NOK
                <br />
                MVA: {product.vat_applicable ? 'Ja' : 'Nei'}
              </div>
            </Tooltip>
          ))}
        </div>
        <div className="mb-6">
          <label className="block text-gray-700">Valgte Produkter</label>
          {invoiceProducts.length === 0 ? (
            <div className="text-gray-700">Ingen produkter valgt</div>
          ) : (
            invoiceProducts.map((product, index) => (
              <div key={index} className="flex flex-col md:flex-row items-center mb-2 space-y-2 md:space-y-0 md:space-x-2">
                <span className="w-full md:w-1/4">{product.name}</span>
                <div className="w-full md:w-1/4 flex items-center">
                  <button
                    type="button"
                    onClick={() => handleQuantityChange(index, -1)}
                    className="px-3 py-3 bg-red-500 text-white rounded-l hover:bg-red-600 transition focus:outline-none"
                  >
                    <FaArrowDown />
                  </button>
                  <input
                    type="number"
                    value={product.quantity}
                    onChange={(e) => handleProductChange(index, 'quantity', parseInt(e.target.value))}
                    className="no-arrows w-full md:w-12 px-4 py-2 border-t border-b border-gray-300 text-center focus:outline-none"
                    min="1"
                  />
                  <button
                    type="button"
                    onClick={() => handleQuantityChange(index, 1)}
                    className="px-3 py-3 bg-green-500 text-white rounded-r hover:bg-green-600 transition focus:outline-none"
                  >
                    <FaArrowUp />
                  </button>
                </div>
                <input
                  type="number"
                  value={product.price}
                  onChange={(e) => handleProductChange(index, 'price', e.target.value)}
                  className="w-full md:w-1/4 px-3 py-2 border rounded"
                  step="0.01"
                />
                <span className="w-full md:w-1/4 text-right">{(product.quantity * product.price).toFixed(2)} NOK</span>
                <button
                  type="button"
                  onClick={() => handleRemoveProduct(index)}
                  className="ml-2 text-red-500 hover:text-red-700 transition"
                >
                  <FaTimes />
                </button>
              </div>
            ))
          )}
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-bold text-gray-700">Totalt</h3>
          <p>
            <strong>Subtotal:</strong> {subtotal.toFixed(2)} NOK
          </p>
          <p>
            <strong>MVA:</strong> {vatTotal.toFixed(2)} NOK
          </p>
          <p>
            <strong>Total:</strong> {total.toFixed(2)} NOK
          </p>
        </div>
        <button type="submit" className="bg-medium-blue-custom text-white px-4 py-2 rounded hover:bg-dark-blue-custom transition">
          Opprett Faktura
        </button>
      </form>
    </div>
  );
};

export default AddInvoice;