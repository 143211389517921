// src/contexts/NotificationContext.js
import React, { createContext, useState } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, options = {}) => {
    const { type = 'info', persist = false, ...otherOptions } = options;
    const id = Date.now();
    setNotifications([...notifications, { id, message, type, persist, ...otherOptions }]);
    
    if (!persist) {
      setTimeout(() => {
        removeNotification(id);
      }, 5000); // Remove notification after 5 seconds
    }
  };

  const removeNotification = (id) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;