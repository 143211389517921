import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SalesByProduct = () => {
  const [sales, setSales] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/${user.id}/sales-by-product`);
        setSales(response.data);
      } catch (err) {
        console.error('Feil ved henting av salg per produkt:', err);
      }
    };

    fetchData();
  }, [user.id]);

  const data = {
    labels: sales.map((sale) => sale.name),
    datasets: [
      {
        label: 'Total Salg (NOK)',
        data: sales.map((sale) => sale.total_sales),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Salg per Produkt',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-bold mb-4">Salg per Produkt</h3>
      <Bar data={data} options={options} />
    </div>
  );
};

export default SalesByProduct;