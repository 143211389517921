import React from 'react';

const LicenseInput = ({ licenseParts, handleInputChange, handlePaste, handleActivateLicense }) => (
  <div>
    <label className="block text-gray-700 text-sm font-bold mb-4" htmlFor="licenseKey">
      Lisensnøkkel
    </label>
    <div className="flex space-x-2 mb-6">
      {licenseParts.map((part, index) => (
        <input
          key={index}
          id={`part-${index}`}
          name={`part-${index}`}
          type="text"
          value={part}
          onChange={(e) => handleInputChange(e, index)}
          onPaste={handlePaste}
          maxLength="5"
          className="w-1/4 px-4 py-2 border rounded text-center shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      ))}
    </div>
    <button
      onClick={handleActivateLicense}
      className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 transition w-full"
    >
      Aktiver Lisens
    </button>
  </div>
);

export default LicenseInput;