import React, { useState } from 'react';

const UserLicenseModal = ({ onAddLicense, onGenerateLicense }) => {
  const [showModal, setShowModal] = useState(false);
  const [tier, setTier] = useState('free');
  const [validityPeriod, setValidityPeriod] = useState(1);
  const [licenseKey, setLicenseKey] = useState('');

  const handleGenerate = () => {
    onGenerateLicense(tier, validityPeriod);
    setShowModal(false);
  };

  const handleAdd = () => {
    onAddLicense(licenseKey);
    setShowModal(false);
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="mt-4 bg-gradient-to-r from-medium-blue-custom to-dark-blue-custom text-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105"
      >
        Manage Licenses
      </button>
      {showModal && (
        <div className="fixed z-50 inset-0 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-dark-bg p-8 rounded-lg shadow-lg max-w-md w-full relative">
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-3 right-3 text-dark-text hover:text-white transition-colors"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-6 text-dark-text">Manage Licenses</h2>
            <div className="mb-6">
              <label className="block text-dark-text mb-2 font-semibold">Existing License Key</label>
              <input
                type="text"
                value={licenseKey}
                onChange={(e) => setLicenseKey(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg bg-dark-input text-dark-text border-dark-border focus:outline-none focus:ring-2 focus:ring-medium-blue-custom"
              />
              <button
                onClick={handleAdd}
                className="mt-4 w-full bg-gradient-to-r from-green-500 to-green-700 text-white px-4 py-3 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105"
              >
                Add Existing License
              </button>
            </div>
            <div className="mb-6">
              <label className="block text-dark-text mb-2 font-semibold">Tier</label>
              <select
                value={tier}
                onChange={(e) => setTier(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg bg-dark-input text-dark-text border-dark-border focus:outline-none focus:ring-2 focus:ring-medium-blue-custom"
              >
                <option value="Gratis">Free</option>
                <option value="Grunnlreggende">Basic</option>
                <option value="Premium">Premium</option>
              </select>
            </div>
            <div className="mb-6">
              <label className="block text-dark-text mb-2 font-semibold">Validity Period (Months)</label>
              <input
                type="number"
                value={validityPeriod}
                onChange={(e) => setValidityPeriod(e.target.value)}
                className="w-full px-4 py-3 border rounded-lg bg-dark-input text-dark-text border-dark-border focus:outline-none focus:ring-2 focus:ring-medium-blue-custom"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105"
              >
                Cancel
              </button>
              <button
                onClick={handleGenerate}
                className="bg-gradient-to-r from-medium-blue-custom to-dark-blue-custom text-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105"
              >
                Generate License
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserLicenseModal;