import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/${user.id}/product-list`);
        setProducts(response.data);
      } catch (err) {
        console.error('Feil ved henting av produktliste:', err);
      }
    };

    fetchData();
  }, [user.id]);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-bold mb-4">Produktliste</h3>
      <ul>
        {products.map((product) => (
          <li key={product.id} className="mb-2 border-b pb-2">
            <div className="text-gray-700"><strong>Navn:</strong> {product.name}</div>
            <div className="text-gray-700"><strong>Beskrivelse:</strong> {product.description}</div>
            <div className="text-gray-700"><strong>Pris:</strong> {product.price} NOK</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductList;