import React from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader';

const CustomerTable = ({ customers, loading = false }) => (
  <table className="min-w-full bg-white rounded-lg shadow overflow-hidden">
    <thead className="bg-dark-blue-new text-white">
      <tr>
        <th className="py-2 px-4 text-left">Navn</th>
        <th className="py-2 px-4 text-left">Kontaktperson</th>
        <th className="py-2 px-4 text-left">Adresse</th>
        <th className="py-2 px-4 text-left">E-post</th>
        <th className="py-2 px-4 text-left">Telefon</th>
        <th className="py-2 px-4 text-left">Status</th>
      </tr>
    </thead>
    <tbody>
      {loading && <Loader />}
      {customers.map((customer) => (
        <tr key={customer.id} className="border-b">
          <td className="py-2 px-4"><Link to={`/kunder/${customer.id}`} className="text-medium-blue-custom hover:text-dark-blue-custom">{customer.name}</Link></td>
          <td className="py-2 px-4">{customer.contact_name}</td>
          <td className="py-2 px-4">{customer.address}</td>
          <td className="py-2 px-4">{customer.email}</td>
          <td className="py-2 px-4">{customer.phone}</td>
          <td className="py-2 px-4">{customer.active ? 'Aktiv' : 'Inaktiv'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default CustomerTable;