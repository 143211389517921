import React, { useState } from 'react';

const LicenseModal = ({ isOpen, onClose, onCreate }) => {
  const [tier, setTier] = useState('basic');
  const [validityPeriodMonths, setValidityPeriodMonths] = useState(12);

  const handleCreate = () => {
    onCreate({ tier, validityPeriodMonths });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center z-50 justify-center bg-black bg-opacity-50">
      <div className="bg-dark-bg p-8 rounded-lg shadow-lg max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-dark-text hover:text-white transition-colors"
        >
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-6 text-dark-text">Create License</h2>
        <div className="mb-6">
          <label className="block text-dark-text mb-2 font-semibold">Tier</label>
          <select
            value={tier}
            onChange={(e) => setTier(e.target.value)}
            className="w-full px-4 py-3 border rounded-lg bg-dark-input text-dark-text border-dark-border focus:outline-none focus:ring-2 focus:ring-medium-blue-custom"
          >
            <option value="Gratis">Free</option>
            <option value="Grunnleggende">Basic</option>
            <option value="Premium">Premium</option>
          </select>
        </div>
        <div className="mb-6">
          <label className="block text-dark-text mb-2 font-semibold">Validity Period (Months)</label>
          <input
            type="number"
            value={validityPeriodMonths}
            onChange={(e) => setValidityPeriodMonths(e.target.value)}
            className="w-full px-4 py-3 border rounded-lg bg-dark-input text-dark-text border-dark-border focus:outline-none focus:ring-2 focus:ring-medium-blue-custom"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            Cancel
          </button>
          <button
            onClick={handleCreate}
            className="bg-gradient-to-r from-medium-blue-custom to-dark-blue-custom text-white px-6 py-3 rounded-lg shadow-md hover:shadow-lg transform transition-transform duration-300 hover:scale-105"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default LicenseModal;