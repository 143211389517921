import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const UpgradePrompt = ({ freeAccess, basicAccess, premiumAccess, featureName }) => {
  const defaultMessage = featureName || 'denne funksjonen';
  let message = '';
  
  if (premiumAccess) {
    message = `Oppgrader til Premium for å få tilgang til ${defaultMessage}.`;
  } else if (basicAccess) {
    message = `Oppgrader til Grunnleggende eller Premium for å få tilgang til ${defaultMessage}.`;
  } else {
    message = `Oppgrader til Grunnleggende eller Premium for å få tilgang til ${defaultMessage}.`;
  }

  return (
    <div className="relative bg-dark-bg p-8 rounded-lg shadow-2xl text-center text-dark-text overflow-hidden border border-dark-border">
      <h2 className="relative text-4xl font-extrabold mb-4 drop-shadow-lg">Oppgrader for å få tilgang til flere funksjoner</h2>
      <p className="relative text-lg mb-6">{message}</p>
      <Link
        to="/innstillinger?tab=prisplan"
        className="relative bg-dark-text text-dark-blue-new font-semibold px-6 py-3 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:scale-105 flex items-center justify-center"
      >
        Se Planer <FaArrowRight className="ml-2" />
      </Link>
      <div className="relative mt-4">
        <p className="text-sm italic">
          Få mest mulig ut av tjenesten vår ved å oppgradere i dag!
        </p>
      </div>
    </div>
  );
};

export default UpgradePrompt;