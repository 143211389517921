import React, { useState, useContext, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import verifyToken from '../utils/verifyToken';

const Home = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [registerEmail, setRegisterEmail] = useState('');
  const [registerPassword, setRegisterPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  useEffect(() => {
    const checkToken = async () => {
      const tokenData = await verifyToken();
      if (tokenData) {
        login(tokenData); // Assuming your tokenData contains user data with a token
        navigate('/dashboard');
      }
    };

    checkToken();
  }, [navigate, login]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log(email, password);
      const response = await axiosInstance.post('/api/auth/login', { email, password });
      login(response.data); // Assuming your response contains user data with a token
      navigate('/dashboard');
    } catch (err) {
      setError('Ugyldig e-post eller passord');
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post('/api/auth/register', { email: registerEmail, password: registerPassword });
      login(response.data); // Assuming your response contains user data with a token
      navigate('/dashboard');
    } catch (err) {
      setError('Registrering feilet');
    }
  };

  return (
      <div className="min-h-screen flex flex-col lg:flex-row bg-cover bg-center" style={{ backgroundImage: "url('/images/site-images/background-pattern.png')", backgroundRepeat: "repeat", backgroundSize: "auto" }}>
      <div className="flex-1 relative flex items-center justify-center lg:justify-start lg:pl-60">
        <img
          src="/images/site-images/logo_new_with_text_corrected.svg"
          alt="Logo"
          className="w-64 lg:w-1/2"
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full lg:w-1/3 bg-white bg-opacity-80 p-8 lg:p-16 rounded-lg lg:rounded-none shadow-md lg:shadow-none lg:min-h-screen lg:max-w-md relative">
        <div className="w-full">
          <h2 className="text-3xl font-bold mb-6 text-dark-blue-custom text-center">{isRegistering ? 'Registrer deg' : 'Logg inn'}</h2>
          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
          <form onSubmit={isRegistering ? handleRegister : handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                E-post
              </label>
              <input
                id="email"
                type="email"
                value={isRegistering ? registerEmail : email}
                onChange={(e) => isRegistering ? setRegisterEmail(e.target.value) : setEmail(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-medium-blue-custom"
                placeholder="Skriv inn din e-post"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Passord
              </label>
              <input
                id="password"
                type="password"
                value={isRegistering ? registerPassword : password}
                onChange={(e) => isRegistering ? setRegisterPassword(e.target.value) : setPassword(e.target.value)}
                required
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-medium-blue-custom"
                placeholder="Skriv inn ditt passord"
              />
            </div>
            <div className="flex flex-col items-center">
              <button type="submit" className="bg-medium-blue-custom text-white px-4 py-2 rounded-lg hover:bg-dark-blue-custom transition mb-4">
                {isRegistering ? 'Registrer deg' : 'Logg inn'}
              </button>
              <button
                type="button"
                className="text-medium-blue-custom underline hover:text-dark-blue-custom transition"
                onClick={() => setIsRegistering(!isRegistering)}
              >
                {isRegistering ? 'Har du allerede en konto? Logg inn' : 'Har du ikke en konto? Registrer deg'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;