import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance'; // Importing axios instance for API calls
import { FaCheck, FaTimes } from 'react-icons/fa'; // Importing icons for check and cross marks
import '../../TierComparisonTable.css'; // Importing CSS file for styling

const TierComparisonTable = () => {
  const [features, setFeatures] = useState([]); // State to store features data

  useEffect(() => {
    // Function to fetch features from API
    const fetchFeatures = async () => {
      try {
        const response = await axiosInstance.get('/api/features');
        const data = response.data;

        // Transform data into the desired format
        const formattedFeatures = data.reduce((acc, item) => {
          const categoryIndex = acc.findIndex((f) => f.category === item.category);
          const featureItem = { ...item.feature };

          if (categoryIndex > -1) {
            acc[categoryIndex].items.push(featureItem); // Add feature item to existing category
          } else {
            acc.push({
              category: item.category,
              items: [featureItem], // Create new category with feature item
            });
          }

          return acc;
        }, []);

        setFeatures(formattedFeatures); // Set the transformed data to state
      } catch (error) {
        console.error('Error fetching features:', error); // Log any errors
      }
    };

    fetchFeatures(); // Fetch features when component mounts
  }, []);

  const tiers = ['Gratis', 'Grunnleggende', 'Premium']; // Define tiers

  // Function to render check or cross marks based on feature availability
  const renderCheck = (value) => {
    if (value === true) return <FaCheck className="text-green-500" />;
    if (value === false) return <FaTimes className="text-red-500" />;
    return value;
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6">Tier Comparison</h2>
      <div className="overflow-x-auto">
        <table className="tier-comparison-table min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border-r border-gray-200 bg-gray-100"></th>
              {tiers.map((tier) => (
                <th key={tier} className="px-4 py-2 border-r border-gray-200 bg-gray-100 text-center">{tier}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature) => (
              <React.Fragment key={feature.category}>
                <tr>
                  <td className="px-4 py-2 font-semibold border-r border-gray-200 bg-gray-50">{feature.category}</td>
                  <td colSpan={tiers.length}></td>
                </tr>
                {feature.items.map((item) => (
                  <tr key={item.name}>
                    <td className="px-4 py-2 border-r border-gray-200">{item.name}</td>
                    {tiers.map((tier) => (
                      <td key={tier} className="px-4 py-2 border-r border-gray-200 text-center">
                        {renderCheck(item[tier])}
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TierComparisonTable;