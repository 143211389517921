import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as necessary
import UserContext from '../../contexts/UserContext';
import Loader from '../Loader'; // Assuming you have a Loader component
import { FaArrowRight } from 'react-icons/fa';
import { lastDayOfMonth, format, parseISO } from 'date-fns';

const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

const FinancialYearPage = () => {
  const { user } = useContext(UserContext);
  const [financialYear, setFinancialYear] = useState({
    start_month: '',
    end_month: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFinancialYear();
  }, [user.id]);

  const fetchFinancialYear = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/financial-years/${user.company_id}`);
      const data = response.data;

      // Extract month part from dates
      const start_date = parseISO(data.start_date);
      const end_date = parseISO(data.end_date);

      setFinancialYear({
        start_month: format(start_date, 'MM'),
        end_month: format(end_date, 'MM'),
      });
      setError(null);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setFinancialYear({
          start_month: '',
          end_month: '',
        });
        setError(null); // Clear any previous errors
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const saveFinancialYear = async () => {
    setLoading(true);
    try {
      const start_date = `2023-${financialYear.start_month}-01`;

      // Calculate the last day of the end month using date-fns
      const end_month = parseInt(financialYear.end_month, 10);
      const end_year = 2023;
      const end_date = format(lastDayOfMonth(new Date(end_year, end_month - 1)), 'yyyy-MM-dd');

      await axiosInstance.post(`/api/financial-years/${user.company_id}`, { start_date, end_date });
      fetchFinancialYear();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteFinancialYear = async () => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/api/financial-years/${user.id}`);
      setFinancialYear({ start_month: '', end_month: '' });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setFinancialYear({ ...financialYear, [field]: value });
  };

  if (loading) return <Loader />;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  return (
    <div className="container mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-3xl font-bold mb-4">Finansielt År</h2>
      <div className="flex items-center justify-center space-x-4">
        <div className="flex items-center space-x-2">
          <div>
            <label className="block text-gray-800 text-sm font-semibold mb-1">Startdato</label>
            <select
              value={financialYear.start_month}
              onChange={(e) => handleInputChange('start_month', e.target.value)}
              className="month-select"
            >
              <option value="">Month</option>
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex items-center justify-center h-full">
          <FaArrowRight className="text-4xl text-gray-500" />
        </div>
        <div className="flex items-center space-x-2">
          <div>
            <label className="block text-gray-800 text-sm font-semibold mb-1">Sluttdato</label>
            <select
              value={financialYear.end_month}
              onChange={(e) => handleInputChange('end_month', e.target.value)}
              className="month-select"
            >
              <option value="">Month</option>
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-4 mt-6">
        <button
          onClick={saveFinancialYear}
          className="bg-blue-500 text-white font-semibold px-8 py-3 rounded-md shadow hover:bg-blue-700 transition duration-200"
        >
          Lagre
        </button>
        <button
          onClick={deleteFinancialYear}
          className="bg-red-500 text-white font-semibold px-8 py-3 rounded-md shadow hover:bg-red-700 transition duration-200"
        >
          Slett
        </button>
      </div>
    </div>
  );
};

export default FinancialYearPage;