import React, { useState } from "react";
import { FaFolder, FaFolderOpen, FaFileAlt } from "react-icons/fa";

const TreeNode = ({ node, onSelect, onAddSubcategory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="ml-4">
      <div className="flex items-center">
        {node.children.length > 0 ? (
          <button onClick={toggleOpen} className="mr-2 focus:outline-none">
            {isOpen ? (
              <FaFolderOpen className="text-yellow-500" />
            ) : (
              <FaFolder className="text-yellow-500" />
            )}
          </button>
        ) : (
          <FaFileAlt className="mr-2 text-gray-500" />
        )}
        <span
          className={`cursor-pointer ${
            node.children.length > 0 ? "font-semibold" : ""
          }`}
          onClick={() => onSelect(node.id, node.name)}
        >
          {node.name}
        </span>
        <button
          onClick={() => onAddSubcategory(node.id)}
          className="ml-2 text-gray-300 hover:text-gray-500"
        >
          +
        </button>
      </div>
      {isOpen && (
        <div className="pl-4">
          {node.children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              onSelect={onSelect}
              onAddSubcategory={onAddSubcategory}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeNode;