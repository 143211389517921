import React from 'react';

const LicenseDetails = ({ license }) => (
  <div className="bg-white border border-gray-200 rounded-xl shadow-md p-8 max-w-lg mx-auto my-8">
    <h2 className="text-3xl font-bold text-gray-800 mb-6 border-b pb-4">Lisensdetaljer</h2>
    <div className="space-y-6">
      <div className="flex items-center">
        <span className="font-medium text-gray-700 w-32">Nøkkel:</span>
        <span className="text-gray-900">{license.key}</span>
      </div>
      <div className="flex items-center">
        <span className="font-medium text-gray-700 w-32">Nivå:</span>
        <span className="text-gray-900">{license.tier}</span>
      </div>
      <div className="flex items-center">
        <span className="font-medium text-gray-700 w-32">Gyldighet:</span>
        <span className="text-gray-900">{license.validity_period_months} måneder</span>
      </div>
      <div className="flex items-center">
        <span className="font-medium text-gray-700 w-32">Opprettet:</span>
        <span className="text-gray-900">{new Date(license.created_at).toLocaleDateString('nb-NO')}</span>
      </div>
      <div className="flex items-center">
        <span className="font-medium text-gray-700 w-32">Aktivert:</span>
        <span className="text-gray-900">{new Date(license.activated_at).toLocaleDateString('nb-NO')}</span>
      </div>
      <div className="flex items-center">
        <span className="font-medium text-gray-700 w-32">Utløper:</span>
        <span className="text-gray-900">{new Date(license.expires_at).toLocaleDateString('nb-NO')}</span>
      </div>
      <div className="flex items-center">
        <span className="font-medium text-gray-700 w-32">Status:</span>
        <span className={`text-gray-900 ${license.is_active ? 'text-green-600' : 'text-red-600'}`}>
          {license.is_active ? 'Aktiv' : 'Inaktiv'}
        </span>
      </div>
    </div>
  </div>
);

export default LicenseDetails;