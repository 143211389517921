import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance'; // Ensure the path to your axios instance is correct

const AddCategoryModal = ({ isOpen, onClose, companyId, parentId, onCategoryAdded }) => {
  const [newCategoryName, setNewCategoryName] = useState('');
  const [parentCategoryName, setParentCategoryName] = useState('');

  useEffect(() => {
    const fetchParentCategoryName = async () => {
      if (parentId) {
        try {
          const response = await axiosInstance.get(`/api/expense-categories/${companyId}/${parentId}`);
          setParentCategoryName(response.data.name);
        } catch (err) {
          console.error('Error fetching parent category name:', err.message);
        }
      }
    };

    fetchParentCategoryName();
  }, [parentId, companyId]);

  const handleAddCategory = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post(`/api/expense-categories/${companyId}`, {
        name: newCategoryName,
        parent_id: parentId,
      });
      onCategoryAdded(response.data);
      onClose();
      setNewCategoryName('');
    } catch (err) {
      console.error('Error adding category:', err.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Legg til ny kategori</h2>
        {parentCategoryName && (
          <p className="text-gray-700 mb-4">Underkategori av: {parentCategoryName}</p>
        )}
        <form onSubmit={handleAddCategory}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="categoryName">
              Kategorinavn
            </label>
            <input
              id="categoryName"
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition"
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
              Legg til
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategoryModal;