import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


// Main
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';

// Invoices
import Invoices from './pages/invoices/Invoices';
import AddInvoice from './pages/invoices/AddInvoice';
import InvoiceDetails from './pages/invoices/InvoiceDetails';
import EditInvoice from './pages/invoices/EditInvoice';

// Credit Notes
import CreditNotes from './pages/credit-notes/CreditNotes';
import CreditNoteDetails from './pages/credit-notes/CreditNoteDetails';

// Customers
import Customers from './pages/customers/Customers';
import CustomerProfile from './pages/customers/CustomerProfile';
import AddCustomer from './pages/customers/AddCustomer';
import EditCustomer from './pages/customers/EditCustomer';

// Products
import Products from './pages/products/Products';
import AddProduct from './pages/products/AddProduct';
import EditProduct from './pages/products/EditProduct';

// Expenses
import Expenses from './pages/expenses/Expenses';

// Auth
import Logout from './pages/auth/Logout';

// Admin
import AdminDashboard from './pages/admin/AdminDashboard';
import ManageUsers from './pages/admin/ManageUsers';
import ManageLicenses from './pages/admin/ManageLicenses';
import UserDetails from './pages/admin/UserDetails';

// Components
import SidebarLayout from './components/SidebarLayout';
import AdminSidebarLayout from './components/admin/AdminSidebarLayout';
import PrivateRoute from './components/PrivateRoute';
import PrivateAdminRoute from './components/admin/PrivateAdminRoute';
import Notification from './components/Notification';

// Contexts
import { UserProvider } from './contexts/UserContext';
import { NotificationProvider } from './contexts/NotificationContext';

const App = () => {
  return (
    <NotificationProvider>
      <UserProvider>
        <Router>
          <Notification />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/logg-ut" element={<Logout />} />
            <Route path="/dashboard" element={<PrivateRoute><SidebarLayout><Dashboard /></SidebarLayout></PrivateRoute>} />
            <Route path="/fakturaer" element={<PrivateRoute><SidebarLayout><Invoices /></SidebarLayout></PrivateRoute>} />
            <Route path="/fakturaer/ny" element={<PrivateRoute><SidebarLayout><AddInvoice /></SidebarLayout></PrivateRoute>} />
            <Route path="/fakturaer/:invoiceId/rediger" element={<PrivateRoute><SidebarLayout><EditInvoice /></SidebarLayout></PrivateRoute>} />
            <Route path="/fakturaer/ny/kunde/:customerId" element={<PrivateRoute><SidebarLayout><AddInvoice /></SidebarLayout></PrivateRoute>} />
            <Route path="/fakturaer/:invoiceId" element={<PrivateRoute><SidebarLayout><InvoiceDetails /></SidebarLayout></PrivateRoute>} />
            <Route path="/kreditnotaer" element={<PrivateRoute><SidebarLayout><CreditNotes /></SidebarLayout></PrivateRoute>} />
            <Route path="/kreditnotaer/:creditNoteId" element={<PrivateRoute><SidebarLayout><CreditNoteDetails /></SidebarLayout></PrivateRoute>} />
            <Route path="/kunder" element={<PrivateRoute><SidebarLayout><Customers /></SidebarLayout></PrivateRoute>} />
            <Route path="/kunder/ny" element={<PrivateRoute><SidebarLayout><AddCustomer /></SidebarLayout></PrivateRoute>} />
            <Route path="/kunder/:customerId" element={<PrivateRoute><SidebarLayout><CustomerProfile /></SidebarLayout></PrivateRoute>} />
            <Route path="/kunder/:customerId/rediger" element={<PrivateRoute><SidebarLayout><EditCustomer /></SidebarLayout></PrivateRoute>} />
            <Route path="/produkter" element={<PrivateRoute><SidebarLayout><Products /></SidebarLayout></PrivateRoute>} />
            <Route path="/produkter/ny" element={<PrivateRoute><SidebarLayout><AddProduct /></SidebarLayout></PrivateRoute>} />
            <Route path="/produkter/:productId" element={<PrivateRoute><SidebarLayout><EditProduct /></SidebarLayout></PrivateRoute>} />
            <Route path="/innstillinger" element={<PrivateRoute><SidebarLayout><Settings /></SidebarLayout></PrivateRoute>} />
            <Route path="/utgifter" element={<PrivateRoute><SidebarLayout><Expenses /></SidebarLayout></PrivateRoute>} />

            {/* Admin Routes */}
            <Route path="/admin/" element={<PrivateAdminRoute><AdminSidebarLayout><AdminDashboard /></AdminSidebarLayout></PrivateAdminRoute>} />
            <Route path="/admin/users" element={<PrivateAdminRoute><AdminSidebarLayout><ManageUsers /></AdminSidebarLayout></PrivateAdminRoute>} />
            <Route path="/admin/users/:id" element={<PrivateAdminRoute><AdminSidebarLayout><UserDetails /></AdminSidebarLayout></PrivateAdminRoute>} />
            <Route path="/admin/licenses" element={<PrivateAdminRoute><AdminSidebarLayout><ManageLicenses /></AdminSidebarLayout></PrivateAdminRoute>} />
          </Routes>
        </Router>
      </UserProvider>
    </NotificationProvider>
  );
};

export default App;