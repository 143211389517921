import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useParams } from 'react-router-dom';
import { FaEdit, FaPrint, FaPaperPlane, FaEnvelope } from 'react-icons/fa';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Loader from '../../components/Loader';
import { handlePrint, handleSend } from '../../utils/creditNoteUtils'; // Assuming you have similar utility functions for credit notes
import Button from '../../components/Button';

const CreditNoteDetails = () => {
  const { creditNoteId } = useParams();
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const [creditNote, setCreditNote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sendingEmail, setSendingEmail] = useState(false);

  useEffect(() => {
    const fetchCreditNote = async () => {
      try {
        const response = await axiosInstance.get(`/api/credit-notes/${user.company_id}/${creditNoteId}`);
        setCreditNote(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCreditNote();
  }, [creditNoteId, user.company_id]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    try {
      await axiosInstance.put(`/api/credit-notes/${user.company_id}/${creditNoteId}/status`, { status: newStatus });
      setCreditNote((prev) => ({ ...prev, status: newStatus }));
      addNotification('Status oppdatert', { type: 'success' });
    } catch (err) {
      addNotification('Feil: Status ble ikke oppdatert', { type: 'error', persist: true });
      setError('Failed to update status');
    }
  };

  const handleSendClick = async () => {
    setSendingEmail(true);
    try {
      await handleSend(user.company_id, creditNoteId, creditNote.customer_email, addNotification);
    } catch (err) {
      console.error('Error sending email:', err);
    } finally {
      setSendingEmail(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!creditNote) {
    return <div>No credit note found.</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-off-white-custom shadow-md rounded-lg">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <h1 className="text-3xl font-bold text-dark-blue-custom mb-4 md:mb-0">Kreditnota</h1>
        <div className="flex items-center">
          <div className="mr-4">
            <p className="text-gray-blue-custom"><strong>Kreditnota nummer:</strong> {creditNote.credit_note_number}</p>
            <p className="text-gray-blue-custom"><strong>Dato:</strong> {new Date(creditNote.credit_note_date).toLocaleDateString('nb-NO')}</p>
            <p className="text-gray-blue-custom"><strong>Forfallsdato:</strong> {new Date(creditNote.due_date).toLocaleDateString('nb-NO')}</p>
          </div>
          <div className="flex items-center">
            {creditNote.email_sent ? (
              <FaEnvelope className="text-green-500 mr-2" title="Kreditnota sendt via e-post" />
            ) : (
              <FaEnvelope className="text-red-500 mr-2" title="Kreditnota ikke sendt" />
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
        <h2 className="text-2xl font-bold text-dark-blue-custom mb-4 md:mb-0">Kundedetaljer</h2>
        <div className="space-x-2 flex items-center">
          <Button text="Endre" icon={<FaEdit />} linkTo={`/kreditnotaer/${creditNoteId}/rediger`} type="secondary" />
          <Button text="Skriv Ut" icon={<FaPrint />} onClick={() => handlePrint(user.company_id, creditNoteId, creditNote.credit_note_number, addNotification)} type="success" />
          <Button text={sendingEmail ? 'Sender...' : 'Send'} icon={!sendingEmail && <FaPaperPlane />} onClick={handleSendClick} type="primary" isLoading={sendingEmail} />
        </div>
      </div>
      <div className="mb-8">
        <p className="text-gray-700"><strong>Navn:</strong> {creditNote.customer_business_name}</p>
        <p className="text-gray-700"><strong>Kontaktperson:</strong> {creditNote.customer_contact_name}</p>
        <p className="text-gray-700"><strong>Adresse:</strong> {creditNote.customer_address}</p>
        <p className="text-gray-700"><strong>E-post:</strong> {creditNote.customer_email}</p>
        <p className="text-gray-700"><strong>Telefon:</strong> {creditNote.customer_phone}</p>
      </div>
      <div className="mb-8 hidden md:block">
        <h2 className="text-2xl font-bold text-dark-blue-custom mb-4">Produktdetaljer</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg overflow-hidden shadow">
            <thead className="bg-medium-blue-custom text-white">
              <tr>
                <th className="py-3 px-4 text-left">Produktnavn</th>
                <th className="py-3 px-4 text-left">Beskrivelse</th>
                <th className="py-3 px-4 text-right">Antall</th>
                <th className="py-3 px-4 text-right">Pris</th>
                <th className="py-3 px-4 text-right">Sum</th>
              </tr>
            </thead>
            <tbody>
              {creditNote.products.map((product) => (
                <tr key={product.id} className="border-b">
                  <td className="py-2 px-4">{product.product_name}</td>
                  <td className="py-2 px-4">{product.product_description}</td>
                  <td className="py-2 px-4 text-right">{product.quantity}</td>
                  <td className="py-2 px-4 text-right">{product.price}</td>
                  <td className="py-2 px-4 text-right">{(product.price * product.quantity).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
        <div className="w-full md:w-1/3 mb-4 md:mb-0">
          <p className="text-gray-700"><strong>Subtotal:</strong> {creditNote.subtotal}</p>
          <p className="text-gray-700"><strong>MVA:</strong> {creditNote.vat_total}</p>
          <p className="text-gray-700"><strong>Total:</strong> {creditNote.total}</p>
        </div>
        <div className="w-full md:w-auto">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
            Status
          </label>
          <select
  id="status"
  value={creditNote.status}
  onChange={handleStatusChange}
  className="w-full px-3 py-2 border rounded"
>
  <option value="issued">Utstedt</option>
  <option value="cancelled">Kansellert</option>
</select>
        </div>
      </div>
    </div>
  );
};

export default CreditNoteDetails;