import React, { useContext } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import MiniInvoiceActions from './MiniInvoiceActions';
import Loader from './Loader';

const InvoiceTable = ({ invoices, onStatusChange, loading }) => {
  const { user } = useContext(UserContext);
  console.log(invoices);

  const handleStatusChange = async (invoiceId, newStatus) => {
    try {
      await axiosInstance.put(`/api/invoices/${user.company_id}/${invoiceId}/status`, { status: newStatus });
      onStatusChange(invoiceId, newStatus);
    } catch (err) {
      console.error('Failed to update status', err);
    }
  };

  return (
    <table className="min-w-full bg-white rounded-lg shadow overflow-hidden">
      <thead className="bg-dark-blue-new text-white">
        <tr>
          <th className="py-2 px-4 text-left">Fakturanummer</th>
          <th className="py-2 px-4 text-left">Kundenavn</th>
          <th className="py-2 px-4 text-right">Total</th>
          <th className="py-2 px-4 text-left">Dato</th>
          <th className="py-2 px-4 text-left">Kunde E-post</th>
          <th className="py-2 px-4 text-left">Kunde Telefon</th>
          <th className="py-2 px-4 text-left">Status</th>
          <th className="py-2 px-4 text-left">Handlinger</th>
        </tr>
      </thead>
      <tbody>
        {loading && <Loader />}
        {invoices.map((invoice) => (
          <tr key={invoice.id} className={`border-b ${invoice.credited ? 'bg-red-100' : ''}`}>
            <td className="py-2 px-4">
              <Link to={`/fakturaer/${invoice?.id}`} className={`text-medium-blue-custom hover:text-dark-blue-custom ${invoice.credited ? 'text-red-500' : ''}`}>
                {invoice.invoice_number}
                {invoice.credited ? <span className="ml-2 text-red-500">(Kreditert)</span> : ''}
              </Link>
            </td>
            <td className="py-2 px-4">{invoice.customer_name}</td>
            <td className="py-2 px-4 text-right">{invoice.total}</td>
            <td className="py-2 px-4">{new Date(invoice.invoice_date).toLocaleDateString('nb-NO')}</td>
            <td className="py-2 px-4">{invoice.customer_email}</td>
            <td className="py-2 px-4">{invoice.customer_phone}</td>
            <td className="py-2 px-4">
              <select
                value={invoice.status}
                onChange={(e) => handleStatusChange(invoice.id, e.target.value)}
                className="px-3 py-1 border rounded bg-gray-100 text-gray-700"
                disabled={invoice.credited}
              >
                <option value="paid">Betalt</option>
                <option value="pending">Ubetalt</option>
                <option value="overdue">Forfalt</option>
                <option value="cancelled">Kansellert</option>
                <option value="credited">Kreditert</option>
              </select>
            </td>
            <td className="py-2 px-4">
              <MiniInvoiceActions invoiceId={invoice.id} invoiceNumber={invoice.invoice_number} customerEmail={invoice.customer_email} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoiceTable;