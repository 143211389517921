import React from "react";
import TreeNode from "./TreeNode";

const buildTree = (categories) => {
  const map = {};
  categories.forEach((category) => {
    category.children = [];
    map[category.id] = category;
  });
  const tree = [];
  categories.forEach((category) => {
    if (category.parent_id) {
      map[category.parent_id].children.push(category);
    } else {
      tree.push(category);
    }
  });
  return tree;
};

const Tree = ({ categories, onSelect, onAddSubcategory }) => {
  const treeData = buildTree(categories);
  return (
    <div>
      {treeData.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          onSelect={onSelect}
          onAddSubcategory={onAddSubcategory}
        />
      ))}
    </div>
  );
};

export default Tree;