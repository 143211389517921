import React, { useContext, useState } from 'react';
import { FaHome, FaFileInvoice, FaUsers, FaBoxOpen, FaCog, FaChartPie, FaMoneyBill, FaSignOutAlt, FaBars } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import Ad from './Ad';

const SidebarLayout = ({ children }) => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const menuItemStyle = 'inline-block mr-2';

  const menuItems = [
    { to: '/dashboard', icon: <FaHome className={menuItemStyle} />, label: 'Dashboard' },
    { to: '/fakturaer', icon: <FaFileInvoice className={menuItemStyle} />, label: 'Fakturaer' },
    { to: '/kreditnotaer', icon: <FaFileInvoice className={menuItemStyle} />, label: 'Kreditnotaer' },
    { to: '/kunder', icon: <FaUsers className={menuItemStyle} />, label: 'Kunder' },
    { to: '/produkter', icon: <FaBoxOpen className={menuItemStyle} />, label: 'Produkter' },
    { to: '/utgifter', icon: <FaMoneyBill className={menuItemStyle} />, label: 'Utgifter' },
    { to: '/innstillinger', icon: <FaCog className={menuItemStyle} />, label: 'Innstillinger' },
  ];

  if (user?.is_admin) {
    menuItems.push({ to: '/admin', icon: <FaChartPie className={menuItemStyle} />, label: 'Admin' });
  }

  const linkClasses = (path) => (
    `flex items-center p-2 my-2 transition-colors duration-200 rounded-lg hover:bg-dark-blue-new hover:text-off-white-custom ${location.pathname === path ? 'bg-dark-blue-new text-off-white-custom' : 'text-dark-blue-new'}`
  );

  const handleLogout = () => {
    navigate('/logg-ut');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLinkClick = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-blue-custom">
      <div className={`fixed inset-0 bg-black opacity-50 z-40 transition-opacity ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
      <aside className={`fixed inset-0 w-full lg:w-64 bg-off-white-custom p-4 flex flex-col transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 transition-transform duration-300 ease-in-out shadow-lg z-50`}>
        <div className="mb-2 flex justify-center">
          <img src="/images/site-images/logo_new_with_text_corrected.svg" alt="Fattern Faktura Logo" className="h-20" />
        </div>
        <ul className="flex-grow overflow-auto">
          {menuItems.map((item) => (
            <li key={item.to}>
              <Link to={item.to} className={linkClasses(item.to)} onClick={handleLinkClick}>
                {item.icon} {item.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className="text-center font-bold mb-4 p-4 rounded-lg bg-dark-blue-new text-off-white-custom shadow-lg">
          <div className="flex flex-col items-center">
          <Link to="/innstillinger?tab=profil" className="text-lg hover:text-blue-500 transition-colors duration-200">
  {user?.company_name}
</Link>
<Link to="/innstillinger?tab=lisens" className="mt-1 text-sm bg-medium-blue-custom text-off-white-custom px-3 py-1 rounded-full hover:bg-dark-blue-custom transition-colors duration-200">
  {user?.tier} Lisens
</Link>
          </div>
        </div>
        <div className="text-center mb-4">
          <button
            onClick={handleLogout}
            className="flex items-center justify-center w-full text-dark-blue-new hover:text-white hover:bg-red-500 transition-colors duration-200 p-2 rounded-lg"
          >
            <FaSignOutAlt className="inline-block mr-2" /> Logg ut
          </button>
        </div>
        <footer className="text-gray-blue-custom mt-4">
          © {new Date().getFullYear()} Fattern Faktura
        </footer>
      </aside>
      <main className="flex-1 p-4 bg-off-white-custom lg:ml-64">
        <div className="lg:hidden fixed top-0 right-0 p-4 z-50">
          <button onClick={toggleSidebar} className="text-dark-blue-custom focus:outline-none">
            <FaBars className="text-2xl" />
          </button>
        </div>
        {children}
      </main>
    </div>
  );
};

export default SidebarLayout;