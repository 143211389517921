import React, { useContext } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext';

const CustomerInvoiceTable = ({ invoices, onStatusChange }) => {
  const { user } = useContext(UserContext);

  const handleStatusChange = async (invoiceId, newStatus) => {
    try {
      await axiosInstance.put(`/api/invoices/${user.company_id}/${invoiceId}/status`, { status: newStatus });
      onStatusChange(invoiceId, newStatus);
    } catch (err) {
      console.error('Failed to update status', err);
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white rounded-lg shadow overflow-hidden">
        <thead className="bg-dark-blue-new text-white">
          <tr>
            <th className="py-2 px-4 text-left">Fakturanummer</th>
            <th className="py-2 px-4 text-right">Total</th>
            <th className="py-2 px-4 text-left">Dato</th>
            <th className="py-2 px-4 text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.id} className="border-b">
              <td className="py-2 px-4">
                <Link to={`/fakturaer/${invoice.id}`} className="text-medium-blue-custom hover:text-dark-blue-custom">
                  {invoice.invoice_number}
                </Link>
              </td>
              <td className="py-2 px-4 text-right">{invoice.total}</td>
              <td className="py-2 px-4">{new Date(invoice.invoice_date).toLocaleDateString()}</td>
              <td className="py-2 px-4">
                <select
                  value={invoice.status}
                  onChange={(e) => handleStatusChange(invoice.id, e.target.value)}
                  className="px-3 py-1 border rounded bg-gray-100 text-gray-700"
                >
                  <option value="paid">Betalt</option>
                  <option value="pending">Ubetalt</option>
                  <option value="overdue">Forfalt</option>
                  <option value="cancelled">Kansellert</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerInvoiceTable;