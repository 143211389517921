import React, { useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Header from '../../components/Header';

const AddProduct = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    vat_applicable: true,
  });
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`/api/products/${user.company_id}`, formData);
      addNotification('Produktet ble lagt til', {type: 'success'});
      navigate('/produkter'); // Redirect to products page
    } catch (err) {
      addNotification('Feil: Produktet ble ikke lagt til', {type: 'error', persist: true});
      setError(err.response?.data?.error || 'Failed to add product');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <Header text="Legg til produkt" />
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Navn</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Beskrivelse</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Pris</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">MVA</label>
            <input
              type="checkbox"
              name="vat_applicable"
              checked={formData.vat_applicable}
              onChange={handleChange}
              className="mr-2"
            />
          </div>
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="bg-medium-blue-custom text-white px-4 py-2 rounded hover:bg-dark-blue-custom transition"
          >
            Legg til produkt
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProduct;