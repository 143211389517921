import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Pagination from '../../components/Pagination';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import NoData from '../../components/NoData';
import Header from '../../components/Header';
import Toolbar from '../../components/Toolbar';
import LicenseModal from '../../components/admin/LicenseModal';
import LicenseTable from '../../components/admin/LicenseTable';

const Licenses = () => {
  const [licenses, setLicenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);

  const fetchLicenses = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/licenses', {
        params: { page, limit, search }
      });
      setLicenses(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleCreateLicense = async ({ tier, validityPeriodMonths }) => {
    try {
      await axiosInstance.post('/api/licenses', { tier, validityPeriodMonths });
      addNotification('License created successfully', { type: 'success' });
      fetchLicenses();
    } catch (err) {
      addNotification('Error creating license', { type: 'error' });
    }
  };

  useEffect(() => {
    fetchLicenses();
  }, [page, limit, search]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <Header text="Licenses" onAddClick={() => setIsModalOpen(true)} theme="dark" />
      <Toolbar
        showSearch
        searchValue={search}
        onSearchChange={setSearch}
        resultsPerPage={limit}
        onResultsPerPageChange={setLimit}
        theme="dark"
      />

      <LicenseTable licenses={licenses} loading={loading} addNotification={addNotification} />

      <Pagination page={page} totalPages={Math.ceil(licenses.length / limit)} onPageChange={setPage} />

      <LicenseModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCreate={handleCreateLicense}
        addNotification={addNotification}
      />
    </div>
  );
};

export default Licenses;