import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Pagination from '../../components/Pagination';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import CustomerTable from '../../components/CustomerTable';
import CustomerCards from '../../components/CustomerCards';
import NoData from '../../components/NoData';
import Header from '../../components/Header';
import Toolbar from '../../components/Toolbar';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState('');
  const [search, setSearch] = useState('');
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);

  const fetchCustomers = async () => {
    setLoading(true);
    console.log(process.env.REACT_APP_API_BASE_URL);
    try {
      const response = await axiosInstance.get(`/api/customers/${user.company_id}`, {
        params: { page, limit, active, search }
      });
      setCustomers(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [page, limit, active, search]);

  const handleImportSuccess = () => {
    fetchCustomers();
    addNotification('Customers imported successfully', { type: 'success' });
  };

  const handleExportSuccess = () => {
    addNotification('Customers exported successfully', { type: 'success' });
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <Header text="Kunder" linkTo="/kunder/ny" />
      <Toolbar
        showSearch
        searchValue={search}
        onSearchChange={setSearch}
        showActiveFilter
        active={active}
        onActiveChange={setActive}
        resultsPerPage={limit}
        onResultsPerPageChange={setLimit}
        showImportExport
        onImport={handleImportSuccess}
        onExport={handleExportSuccess}
      />

      {!loading && customers.length === 0 && <NoData text="Ingen kunder funnet." />}
      <div className="hidden lg:block">
        <CustomerTable customers={customers} loading={loading} />
      </div>
      <div className="lg:hidden">
        <CustomerCards customers={customers} loading={loading} />
      </div>
      <Pagination page={page} totalPages={Math.ceil(customers.length / limit)} onPageChange={setPage} />
    </div>
  );
};

export default Customers;