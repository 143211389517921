import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';

const CustomerOverview = () => {
  const [customers, setCustomers] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/${user.id}/customer-overview`);
        setCustomers(response.data);
      } catch (err) {
        console.error('Feil ved henting av kundeoversikt:', err);
      }
    };

    fetchData();
  }, [user.id]);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-bold mb-4">Kundeoversikt</h3>
      <ul>
        {customers.map((customer) => (
          <li key={customer.id} className="mb-2 border-b pb-2">
            <div className="text-gray-700"><strong>Navn:</strong> {customer.name}</div>
            <div className="text-gray-700"><strong>Kontaktperson:</strong> {customer.contact_name}</div>
            <div className="text-gray-700"><strong>E-post:</strong> {customer.email}</div>
            <div className="text-gray-700"><strong>Telefon:</strong> {customer.phone}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomerOverview;