import React from "react";
import { FaTrash } from "react-icons/fa";

const ExpenseCard = ({ expense, baseApiUrl, fetchPdf, openModal, onDelete }) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2 sm:p-4">
      <div className="bg-gray-blue-custom rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
        {expense.image_path && (
          <img
            src={`${baseApiUrl}/${expense.image_path}`}
            alt={`Expense ${expense.total_sum}`}
            className="w-full h-40 object-cover"
          />
        )}
        <div className="p-4">
          <p className="text-dark-blue-custom font-semibold">
            Sum: {expense.total_sum} NOK
          </p>
          <p className="text-medium-blue-custom">
            Dato: {new Date(expense.expense_date).toLocaleDateString("nb-NO")}
          </p>
          <div className="flex justify-between pt-4">
            {expense.pdf_path && (
              <button
                onClick={() => fetchPdf(expense.pdf_path)}
                className="text-dark-blue-custom border border-dark-blue-custom px-2 py-1 rounded hover:bg-dark-blue-custom hover:text-white transition"
              >
                PDF
              </button>
            )}
            {expense.image_path && (
              <button
                onClick={() =>
                  openModal(`${baseApiUrl}/${expense.image_path}`, "image")
                }
                className="text-dark-blue-custom border border-dark-blue-custom px-2 py-1 rounded hover:bg-dark-blue-custom hover:text-white transition"
              >
                Bilde
              </button>
            )}
            <button
              onClick={() => onDelete(expense.id)}
              className="text-red-500 border border-red-500 px-2 py-1 rounded hover:bg-red-500 hover:text-white transition"
            >
              <FaTrash />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseCard;