import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { FaPlusCircle } from "react-icons/fa";
import UserContext from "../../contexts/UserContext";
import AddCategoryModal from "../../components/modals/AddCategoryModal";
import AddExpenseModal from "../../components/modals/AddExpenseModal";
import Header from "../../components/Header";
import Modal from "react-modal";
import { Document, Page } from "react-pdf";
import Tree from "../../components/expenses/Tree";
import ExpenseList from "../../components/expenses/ExpenseList";

const Expenses = () => {
  const [categories, setCategories] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [expenseModalOpen, setExpenseModalOpen] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalType, setModalType] = useState(null);

  const baseApiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/expense-categories/${user.company_id}`
        );
        setCategories(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchCategories();
  }, [user, categoryModalOpen, expenseModalOpen]);

  const fetchExpenses = async () => {
    try {
      const response = await axiosInstance.get(`/api/expenses/${user.company_id}`, {
        params: {
          categoryId: selectedCategory,
        },
      });
      setExpenses(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [user, selectedCategory, expenseModalOpen, categoryModalOpen]);

  const handleCategorySelect = (categoryId, categoryName) => {
    setSelectedCategory(categoryId);
    setSelectedCategoryName(categoryName);
  };

  const handleAddSubcategory = (categoryId) => {
    setParentId(categoryId);
    setCategoryModalOpen(true);
  };

  const handleAddExpense = () => {
    setExpenseModalOpen(true);
  };

  const handleCategoryAdded = (newCategory) => {
    setCategories([...categories, newCategory]);
  };

  const handleExpenseAdded = (newExpense) => {
    setExpenses([...expenses, newExpense]);
  };

  const openModal = (content, type) => {
    setModalContent(content);
    setModalType(type);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent(null);
    setModalType(null);
  };

  const fetchPdf = async (pdfPath) => {
    window.open(`${baseApiUrl}/${pdfPath}`, "_blank");
  };

  const calculateTotalExpense = (expenses) => {
    return expenses.reduce((total, expense) => {
      const amount = parseFloat(expense.total_sum);
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const totalExpense = calculateTotalExpense(expenses);

  return (
    <div className="flex flex-col bg-off-white-custom p-4 sm:p-6 min-h-full">
      <header className="mb-4 sm:mb-8 flex justify-between items-center">
        <Header text="Utgifter" />
      </header>
      <div className="flex flex-col sm:flex-row flex-1">
        <aside className="w-full sm:w-1/4 bg-dark-blue-new text-white shadow-lg rounded-lg p-4 sm:p-6 mb-4 sm:mb-0 sm:mr-8">
          <div className="flex justify-between items-center mb-4 sm:mb-8">
            <h2 className="text-xl sm:text-2xl font-bold">Kategorier</h2>
            <button
              onClick={() => {
                setParentId(null);
                setCategoryModalOpen(true);
              }}
              className="text-white hover:text-gray-300 transition"
            >
              <FaPlusCircle className="w-6 h-6" />
            </button>
          </div>
          <Tree
            categories={categories}
            onSelect={handleCategorySelect}
            onAddSubcategory={handleAddSubcategory}
          />
        </aside>
        <section className="flex-1 bg-white p-4 sm:p-8 rounded-lg shadow-lg flex flex-col">
          <div className="flex justify-between items-center mb-4 sm:mb-8 border-b border-gray-200 pb-4">
          <div className="space-y-2">
              <h3 className="text-lg sm:text-xl font-semibold text-gray-800">
                Kategori: <span className="text-gray-600">{selectedCategoryName || "Ingen valgt"}</span>
              </h3>
              <div className="bg-gray-100 p-2 rounded">
                <p className="text-sm sm:text-sm font-semibold text-green-600">
                  Totalutgifter i denne kategorien: <span className="text-gray-800">{totalExpense} NOK</span>
                </p>
              </div>
            </div>
            <button
              onClick={handleAddExpense}
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
            >
              Legg til utgift
            </button>
          </div>
          <ExpenseList
            expenses={expenses}
            baseApiUrl={baseApiUrl}
            companyId={user.company_id}
            fetchPdf={fetchPdf}
            openModal={openModal}
            fetchExpenses={fetchExpenses}
          />
        </section>
      </div>

      <AddCategoryModal
        isOpen={categoryModalOpen}
        onClose={() => setCategoryModalOpen(false)}
        userId={user.id}
        companyId={user.company_id}
        parentId={parentId}
        onCategoryAdded={handleCategoryAdded}
      />
      <AddExpenseModal
        isOpen={expenseModalOpen}
        onClose={() => setExpenseModalOpen(false)}
        userId={user.id}
        companyId={user.company_id}
        categoryId={selectedCategory}
        onExpenseAdded={handleExpenseAdded}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Expense Viewer"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 text-white bg-red-500 rounded-full p-2"
        >
          X
        </button>
        {modalType === "pdf" && (
          <Document file={modalContent}>
            <Page pageNumber={1} />
          </Document>
        )}
        {modalType === "image" && (
          <img
            src={modalContent}
            alt="Expense"
            className="w-full h-full object-cover"
          />
        )}
      </Modal>
    </div>
  );
};

export default Expenses;