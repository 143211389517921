import React, { useState, useContext, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Masonry from 'react-masonry-css';
import ComponentModal from '../components/ComponentModal';
import OutstandingPayments from '../components/widgets/OutstandingPayments';
import RecentInvoices from '../components/widgets/RecentInvoices';
import CustomerOverview from '../components/widgets/CustomerOverview';
import ExpenseSummary from '../components/widgets/ExpenseSummary';
import ProductList from '../components/widgets/ProductList';
import RevenueVsExpenses from '../components/widgets/RevenueVsExpenses';
import SalesByProduct from '../components/widgets/SalesByProduct';
import VatSummary from '../components/widgets/VatSummary';
import UserContext from '../contexts/UserContext';
import axiosInstance from '../utils/axiosInstance';
import Header from '../components/Header';

const componentsList = {
  'Siste Fakturaer': <RecentInvoices />,
  'Utestående Betalinger': <OutstandingPayments />,
  'Kundeoversikt': <CustomerOverview />,
  'Utgiftsoversikt': <ExpenseSummary />,
  'Produktliste': <ProductList />,
  'Inntekter vs Utgifter': <RevenueVsExpenses />,
  'Salg per Produkt': <SalesByProduct />,
  'MVA-oversikt': <VatSummary />,
};

const Dashboard = () => {
  const [components, setComponents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    console.log(user.tier);
    // Fetch saved layout from database
    const fetchLayout = async () => {
      try {
        const response = await axiosInstance.get(`/api/dashboard-layout/${user.id}`);
        setComponents(response.data.components || []);
      } catch (err) {
        console.error('Error fetching dashboard layout:', err);
      }
    };

    fetchLayout();
  }, [user.id]);

  const moveComponent = (fromIndex, toIndex) => {
    const updatedComponents = [...components];
    const [movedComponent] = updatedComponents.splice(fromIndex, 1);
    updatedComponents.splice(toIndex, 0, movedComponent);
    setComponents(updatedComponents);
    saveLayout(updatedComponents); // Save layout to the database
  };

  const handleAddComponent = (componentName) => {
    const updatedComponents = [...components, componentName];
    setComponents(updatedComponents);
    setIsModalOpen(false);
    saveLayout(updatedComponents); // Save layout to the database
  };

  const saveLayout = async (layout) => {
    try {
      await axiosInstance.post(`/api/dashboard-layout/${user.id}`, { components: layout });
    } catch (err) {
      console.error('Error saving dashboard layout:', err);
    }
  };

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container mx-auto p-4">
      <Header
        text="Ditt Dashboard"
        onAddClick={() => setIsModalOpen(true)}
        theme="light"
      />
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {components.map((key, index) => (
            <DraggableComponent
              key={index}
              index={index}
              moveComponent={moveComponent}
              component={componentsList[key]}
              onRemove={() => {
                const updatedComponents = components.filter((_, i) => i !== index);
                setComponents(updatedComponents);
                saveLayout(updatedComponents); // Save layout to the database
              }}
            />
          ))}
        </Masonry>
      </div>
      <ComponentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAddComponent={handleAddComponent}
      />
    </DndProvider>
  );
};

const DraggableComponent = ({ component, index, moveComponent, onRemove }) => {
  const [, ref] = useDrag({
    type: 'COMPONENT',
    item: { index },
  });

  const [, drop] = useDrop({
    accept: 'COMPONENT',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveComponent(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} className="widget relative">
      {component}
      <button
        onClick={onRemove}
        className="absolute top-0 right-0 mt-2 mr-2 text-red-500"
      >
        X
      </button>
    </div>
  );
};

export default Dashboard;