import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Pagination from '../../components/Pagination';
import CreditNoteTable from '../../components/CreditNoteTable';
import CreditNoteCards from '../../components/CreditNoteCards';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import UserContext from '../../contexts/UserContext';
import Header from '../../components/Header';
import Toolbar from '../../components/Toolbar';

const CreditNotes = () => {
  const [creditNotes, setCreditNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const [totalCreditNotes, setTotalCreditNotes] = useState(0);
  const { user } = useContext(UserContext);

  const fetchCreditNotes = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/credit-notes/${user.company_id}`, {
        params: { page, limit, status, startDate, endDate, search }
      });
      setCreditNotes(response.data.creditNotes);
      setTotalCreditNotes(response.data.total);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCreditNotes();
  }, [page, limit, status, startDate, endDate, search]);

  const totalPages = Math.ceil(totalCreditNotes / limit);

  const handleStatusChange = (creditNoteId, newStatus) => {
    setCreditNotes(creditNotes.map(creditNote =>
      creditNote.id === creditNoteId ? { ...creditNote, status: newStatus } : creditNote
    ));
  };

  return (
    <div className="p-4">
      <Header text="Kreditnotaer" linkTo="/kreditnotaer/ny" />
      <Toolbar
        showSearch
        searchValue={search}
        onSearchChange={setSearch}
        showStatusFilter
        status={status}
        onStatusChange={setStatus}
        showDateFilters
        startDate={startDate}
        onStartDateChange={setStartDate}
        endDate={endDate}
        onEndDateChange={setEndDate}
        resultsPerPage={limit}
        onResultsPerPageChange={setLimit}
      />
      {creditNotes.length > 0 ? (
        <>
          <div className="hidden lg:block">
            <CreditNoteTable creditNotes={creditNotes} onStatusChange={handleStatusChange} loading={loading} />
          </div>
          <div className="block lg:hidden">
            <CreditNoteCards creditNotes={creditNotes} onStatusChange={handleStatusChange} loading={loading} />
          </div>
        </>
      ) : (
        <NoData text="Ingen kreditnotaer funnet." />
      )}
      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
    </div>
  );
};

export default CreditNotes;