import React, { useState, useEffect, useContext } from 'react';
import { FaEdit, FaRegSave, FaRegTimesCircle } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';
import Subheader from '../Subheader';

const Profile = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [profile, setProfile] = useState({
    email: '',
    first_name: '',
    last_name: '',
    contact_number: '',
    profile_picture: '',
    dark_mode: false,
    receive_notifications: false,
    date_of_birth: '',
  });
  const [profilePictureFile, setProfilePictureFile] = useState(null); // State for the profile picture file
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProfile = async () => {
    try {
      const response = await axiosInstance.get(`/api/users/${user.id}`);
      console.log(response.data);
      const userProfile = response.data;
      setProfile({
        email: userProfile.email,
        first_name: userProfile.first_name || '',
        last_name: userProfile.last_name || '',
        contact_number: userProfile.contact_number || '',
        profile_picture: userProfile.profile_picture || '',
        dark_mode: userProfile.dark_mode || false,
        receive_notifications: userProfile.receive_notifications || false,
        date_of_birth: userProfile.date_of_birth || '',
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [user.id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'file') {
      setProfilePictureFile(e.target.files[0]);
    } else {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', profile.email);
    formData.append('first_name', profile.first_name);
    formData.append('last_name', profile.last_name);
    formData.append('contact_number', profile.contact_number);
    formData.append('dark_mode', profile.dark_mode ? 1 : 0); // Convert boolean to integer
  formData.append('receive_notifications', profile.receive_notifications ? 1 : 0); // Convert boolean to integer
  formData.append('date_of_birth', profile.date_of_birth ? new Date(profile.date_of_birth).toISOString().split('T')[0] : '');
    if (profilePictureFile) {
      formData.append('profile_picture', profilePictureFile);
    }

    try {
      await axiosInstance.put(`/api/users/${user.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setEditMode(false);
      fetchProfile();
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <Subheader text="Profil" subtitle="Dette er din profil" />
      {editMode ? (
        <form onSubmit={handleSubmit} className="space-y-6" encType="multipart/form-data">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Field
              label="E-post"
              id="email"
              value={profile.email}
              onChange={handleChange}
              readOnly
            />
            <Field
              label="Fornavn"
              id="first_name"
              value={profile.first_name}
              onChange={handleChange}
            />
            <Field
              label="Etternavn"
              id="last_name"
              value={profile.last_name}
              onChange={handleChange}
            />
            <Field
              label="Kontaktnummer"
              id="contact_number"
              value={profile.contact_number}
              onChange={handleChange}
            />
            <Field
              label="Profilbilde URL"
              id="profile_picture"
              type="file" // Change input type to file
              onChange={handleChange}
            />
            <Field
              label="Fødselsdato"
              id="date_of_birth"
              type="date"
              value={profile.date_of_birth}
              onChange={handleChange}
            />
            <CheckboxField
              label="Mørk Modus"
              id="dark_mode"
              checked={profile.dark_mode}
              onChange={handleChange}
            />
            <CheckboxField
              label="Motta Varsler"
              id="receive_notifications"
              checked={profile.receive_notifications}
              onChange={handleChange}
            />
          </div>
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setEditMode(false)}
              className="flex items-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
            >
              <FaRegTimesCircle className="mr-2" /> Avbryt
            </button>
            <button
              type="submit"
              className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
              <FaRegSave className="mr-2" /> Lagre
            </button>
          </div>
        </form>
      ) : (
        <div className="space-y-4 bg-white p-6 rounded-lg shadow-md">
  <div className="flex items-center mb-4">
    <img
      src={REACT_APP_API_BASE_URL + '/' + profile.profile_picture || 'default_profile_picture_url'} // Use a default profile picture URL if none is provided
      alt="Profile"
      className="w-24 h-24 rounded-full mr-4"
    />
    <div>
      <h3 className="text-2xl font-bold text-gray-800">{profile.first_name} {profile.last_name}</h3>
      <p className="text-gray-600">{profile.email}</p>
    </div>
  </div>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
    <div>
      <p><strong>Kontaktnummer:</strong> {profile.contact_number}</p>
      <p><strong>Fødselsdato:</strong> {new Date(profile.date_of_birth).toLocaleDateString('nb-NO')}</p>
    </div>
    <div>
      <p><strong>Mørk Modus:</strong> {profile.dark_mode ? 'Ja' : 'Nei'}</p>
      <p><strong>Motta Varsler:</strong> {profile.receive_notifications ? 'Ja' : 'Nei'}</p>
    </div>
  </div>
  <button
    onClick={() => setEditMode(true)}
    className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition"
  >
    <FaEdit className="mr-2" /> Rediger Profil
  </button>
</div>
      )}
    </div>
  );
};

const Field = ({ label, id, value, onChange, type = 'text', readOnly = false }) => (
  <div className="mb-6">
    <label className="block text-gray-800 text-sm font-semibold mb-2" htmlFor={id}>
      {label}
    </label>
    <input
      id={id}
      name={id}
      type={type}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200 focus:outline-none"
    />
  </div>
);

const CheckboxField = ({ label, id, checked, onChange }) => (
  <div className="flex items-center mb-6">
    <input
      id={id}
      name={id}
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className="mr-2"
    />
    <label className="text-gray-800 text-sm font-semibold" htmlFor={id}>
      {label}
    </label>
  </div>
);

export default Profile;