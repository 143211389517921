import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RecentInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/${user.id}/recent-invoices`);
        setInvoices(response.data);
      } catch (err) {
        console.error('Feil ved henting av nylige fakturaer:', err);
      }
    };

    fetchData();
  }, [user.id]);

  const data = {
    labels: invoices.map(invoice => new Date(invoice.due_date).toLocaleDateString('nb-NO')),
    datasets: [
      {
        label: 'Totalbeløp (NOK)',
        data: invoices.map(invoice => invoice.total),
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Nylige Fakturaer Over Tid',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-bold mb-4">Nylige Fakturaer</h3>
      <Line data={data} options={options} />
      <ul className="mt-4">
        {invoices.map((invoice) => (
          <li key={invoice.id} className="mb-4 border-b pb-2">
            <div className="text-gray-700"><strong>Fakturanummer:</strong> {invoice.invoice_number}</div>
            <div className="text-gray-700"><strong>Kunde:</strong> {invoice.customer_id}</div>
            <div className="text-gray-700"><strong>Total:</strong> {invoice.total} NOK</div>
            <div className="text-gray-700"><strong>Forfallsdato:</strong> {new Date(invoice.due_date).toLocaleDateString('nb-NO')}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentInvoices;