import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

const Header = ({ text, subtitle, linkTo, onAddClick, theme = 'light' }) => {
  const isDark = theme === 'dark';

  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
      <div>
        <div className="flex items-center space-x-2">
          <h1 className={`text-2xl sm:text-3xl font-bold ${isDark ? 'text-dark-text' : 'text-dark-blue-new'}`}>{text}</h1>
          {linkTo ? (
            <Link
              to={linkTo}
              className={`transition ${isDark ? 'text-dark-text hover:text-white' : 'text-medium-blue-custom hover:text-dark-blue-custom'}`}
            >
              <div className={`p-2 rounded-full ${isDark ? 'bg-dark-hover text-dark-text hover:bg-white' : 'bg-gray-300 text-dark-blue-new hover:bg-dark-blue-new hover:text-white'}`}>
                <FaPlus className="text-sm" />
              </div>
            </Link>
          ) : (
            onAddClick && (
              <button
                onClick={onAddClick}
                className={`transition ${isDark ? 'text-dark-text hover:text-white' : 'text-medium-blue-custom hover:text-dark-blue-custom'}`}
              >
                <div className={`p-2 rounded-full ${isDark ? 'bg-dark-hover text-dark-text hover:bg-white' : 'bg-gray-300 text-dark-blue-new hover:bg-dark-blue-new hover:text-white'}`}>
                  <FaPlus className="text-sm" />
                </div>
              </button>
            )
          )}
        </div>
        {subtitle && <p className="text-sm text-gray-500 mt-1">{subtitle}</p>}
      </div>
    </div>
  );
};

export default Header;