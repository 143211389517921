import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Pagination from '../../components/Pagination';
import UserContext from '../../contexts/UserContext';
import ProductTable from '../../components/ProductTable';
import ProductCards from '../../components/ProductCards';
import NoData from '../../components/NoData';
import Loader from '../../components/Loader';
import Header from '../../components/Header';
import Toolbar from '../../components/Toolbar';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [totalProducts, setTotalProducts] = useState(0);
  const { user } = useContext(UserContext);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/products/${user.company_id}`, {
        params: { page, limit, search }
      });
      setProducts(response.data.products);
      setTotalProducts(response.data.total);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [page, limit, search]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <Header text="Produkter" linkTo="/produkter/ny" />
      <Toolbar
  showSearch
  searchValue={search}
  onSearchChange={setSearch}
  resultsPerPage={limit}
  onResultsPerPageChange={setLimit}
/>
      {!loading && products.length === 0 && <NoData text="Ingen produkter funnet." />}
      <div className="hidden lg:block">
        <ProductTable products={products} loading={loading} />
      </div>
      <div className="lg:hidden">
        <ProductCards products={products} loading={loading} />
      </div>
      <Pagination page={page} totalPages={Math.ceil(totalProducts / limit)} onPageChange={setPage} />
    </div>
  );
};

export default Products;