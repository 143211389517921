import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as necessary
import UserContext from '../../contexts/UserContext';
import Loader from '../Loader'; // Assuming you have a Loader component
import { startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear } from 'date-fns';

const Reports = () => {
  const { user } = useContext(UserContext);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState('custom');

  useEffect(() => {
    fetchReports();
  }, [user.id]);

  const fetchReports = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/reports/company/${user.company_id}`);
      setReports(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const generateReport = async () => {
    setLoading(true);
    try {
      await axiosInstance.post('/api/reports/generate/financial-summary', {
        companyId: user.company_id,
        startDate,
        endDate,
      });
      fetchReports();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteReport = async (id) => {
    setLoading(true);
    try {
      await axiosInstance.delete(`/api/reports/${id}`);
      fetchReports();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateRangeChange = (e) => {
    const value = e.target.value;
    setDateRange(value);
    switch (value) {
      case 'currentMonth':
        setStartDate(startOfMonth(new Date()).toISOString().split('T')[0]);
        setEndDate(endOfMonth(new Date()).toISOString().split('T')[0]);
        break;
      case 'lastMonth':
        setStartDate(startOfMonth(subMonths(new Date(), 1)).toISOString().split('T')[0]);
        setEndDate(endOfMonth(subMonths(new Date(), 1)).toISOString().split('T')[0]);
        break;
      case 'currentYear':
        setStartDate(startOfYear(new Date()).toISOString().split('T')[0]);
        setEndDate(endOfYear(new Date()).toISOString().split('T')[0]);
        break;
      default:
        setStartDate('');
        setEndDate('');
    }
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6">Rapporter</h2>
      <div className="mb-6">
        <h3 className="text-2xl font-bold mb-4">Generer Rapport</h3>
        <div className="flex space-x-4">
          <select
            value={dateRange}
            onChange={handleDateRangeChange}
            className="px-3 py-2 border rounded"
          >
            <option value="custom">Tilpasset</option>
            <option value="currentMonth">Denne måneden</option>
            <option value="lastMonth">Forrige måned</option>
            <option value="currentYear">Dette året</option>
          </select>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="px-3 py-2 border rounded"
            disabled={dateRange !== 'custom'}
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="px-3 py-2 border rounded"
            disabled={dateRange !== 'custom'}
          />
          <button
            onClick={generateReport}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
          >
            Generer
          </button>
        </div>
      </div>
      <div>
        <h3 className="text-2xl font-bold mb-4">Dine Rapporter</h3>
        {reports.length === 0 ? (
          <p>Ingen rapporter funnet.</p>
        ) : (
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 border-r border-gray-200">Rapport ID</th>
                <th className="px-4 py-2 border-r border-gray-200">Type</th>
                <th className="px-4 py-2 border-r border-gray-200">Opprettet</th>
                <th className="px-4 py-2">Handling</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr key={report.id}>
                  <td className="px-4 py-2 border-r border-gray-200">{report.id}</td>
                  <td className="px-4 py-2 border-r border-gray-200">{report.report_type}</td>
                  <td className="px-4 py-2 border-r border-gray-200">{new Date(report.created_at).toLocaleDateString('nb-NO')}</td>
                  <td className="px-4 py-2 flex space-x-2">
                    <button
                      onClick={() => deleteReport(report.id)}
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
                    >
                      Slett
                    </button>
                    <button
                      onClick={() => alert(JSON.stringify(report.report_data, null, 2))}
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
                    >
                      Vis
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Reports;