import axiosInstance from './axiosInstance';

export const handlePrint = async (companyId, creditNoteId, creditNoteNumber, addNotification) => {
  try {
    const response = await axiosInstance.get(`/api/credit-notes/${companyId}/${creditNoteId}/pdf`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `kreditnota_${creditNoteNumber}.pdf`);
    document.body.appendChild(link);
    link.click();
    addNotification('PDF ble generert.', { type: 'success' });
  } catch (err) {
    console.error('Error generating PDF:', err);
    addNotification('Kunne ikke generere PDF.', { type: 'error', persist: true });
  }
};

export const handleSend = async (companyId, creditNoteId, email, addNotification) => {
  try {
    await axiosInstance.post(`/api/credit-notes/${companyId}/${creditNoteId}/send-pdf`, { email });
    addNotification(`E-post sendt til ${email}`, { type: 'success' });
  } catch (err) {
    console.error('Error sending email:', err);
    addNotification('Kunne ikke sende e-post.', { type: 'error', persist: true });
  }
};