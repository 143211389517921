import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RevenueVsExpenses = () => {
  const [data, setData] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/${user.id}/revenue-vs-expenses`);
        setData(response.data);
      } catch (err) {
        console.error('Feil ved henting av inntekter vs utgifter:', err);
      }
    };

    fetchData();
  }, [user.id]);

  const chartData = {
    labels: ['Inntekter', 'Utgifter'],
    datasets: [
      {
        label: 'NOK',
        data: [data.total_revenue, data.total_expenses],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Inntekter vs Utgifter',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-bold mb-4">Inntekter vs Utgifter</h3>
      <div className="text-gray-700 text-4xl mb-4">
        <div className="mb-2">
          <strong>Total Inntekter:</strong> {data.total_revenue} NOK
        </div>
        <div>
          <strong>Total Utgifter:</strong> {data.total_expenses} NOK
        </div>
      </div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default RevenueVsExpenses;