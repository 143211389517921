import React from 'react';
import ImportCustomers from './customers/ImportCustomers';
import ExportCustomers from './customers/ExportCustomers';
import UserContext from '../contexts/UserContext';

const Toolbar = ({
  showSearch, searchValue, onSearchChange,
  showStatusFilter, status, onStatusChange,
  showDateFilters, startDate, onStartDateChange, endDate, onEndDateChange,
  showActiveFilter, active, onActiveChange,
  resultsPerPage, onResultsPerPageChange,
  showImportExport,
  theme = 'light',
  onImport,
  onExport
}) => {
  const { user } = React.useContext(UserContext);
  const isDark = theme === 'dark';

  const notFreePlan = user && user.tier !== 'Gratis';

  return (
    <div className={`p-4 rounded-lg shadow mb-6 border ${isDark ? 'bg-dark-bg border-dark-border text-dark-text' : 'bg-white border-gray-200'}`}>
      <div className="flex flex-wrap justify-between items-center space-y-4 lg:space-y-0 lg:space-x-4">
        {showSearch && (
          <input
            type="text"
            placeholder="Søk"
            value={searchValue}
            onChange={(e) => onSearchChange(e.target.value)}
            className={`px-3 py-2 border rounded w-full lg:w-auto ${isDark ? 'bg-dark-input text-dark-text border-dark-border' : ''}`}
          />
        )}
        {showStatusFilter && (
          <select
            value={status}
            onChange={(e) => onStatusChange(e.target.value)}
            className={`px-3 py-2 border rounded w-full lg:w-auto ${isDark ? 'bg-dark-input text-dark-text border-dark-border' : ''}`}
          >
            <option value="">Status</option>
            <option value="paid">Betalt</option>
            <option value="unpaid">Ubetalt</option>
            <option value="overdue">Forfalt</option>
          </select>
        )}
        {showDateFilters && (
          <>
            <input
              type="date"
              value={startDate}
              onChange={(e) => onStartDateChange(e.target.value)}
              className={`px-3 py-2 border rounded w-full lg:w-auto ${isDark ? 'bg-dark-input text-dark-text border-dark-border' : ''}`}
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => onEndDateChange(e.target.value)}
              className={`px-3 py-2 border rounded w-full lg:w-auto ${isDark ? 'bg-dark-input text-dark-text border-dark-border' : ''}`}
            />
          </>
        )}
        {showActiveFilter && (
          <select
            value={active}
            onChange={(e) => onActiveChange(e.target.value)}
            className={`px-3 py-2 border rounded w-full lg:w-auto ${isDark ? 'bg-dark-input text-dark-text border-dark-border' : ''}`}
          >
            <option value="">Alle</option>
            <option value="1">Aktiv</option>
            <option value="0">Inaktiv</option>
          </select>
        )}
        <div className="flex items-center space-x-2 w-full lg:w-auto">
          <label className="mr-2">Resultater per side:</label>
          <select
            value={resultsPerPage}
            onChange={(e) => onResultsPerPageChange(e.target.value)}
            className={`px-3 py-2 border rounded ${isDark ? 'bg-dark-input text-dark-text border-dark-border' : ''}`}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        {showImportExport && notFreePlan && (
            <div className="flex items-center space-x-4 mt-4 lg:mt-0">
              <ImportCustomers onImport={onImport} />
              <ExportCustomers onExport={onExport} />
            </div>
        )}
      </div>
    </div>
  );
};

export default Toolbar;