import React, { useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';

const AddExpenseModal = ({ isOpen, onClose, companyId, categoryId, onExpenseAdded }) => {
  const [expenseDate, setExpenseDate] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenseFile, setExpenseFile] = useState(null);

  const handleAddExpense = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('company_id', companyId);
    formData.append('category_id', categoryId);
    formData.append('expense_date', expenseDate);
    formData.append('total_sum', expenseAmount);
    formData.append('file', expenseFile);

    try {
      const response = await axiosInstance.post('/api/expenses', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      onExpenseAdded(response.data);
      onClose();
      setExpenseDate('');
      setExpenseAmount('');
      setExpenseFile(null);
    } catch (err) {
      console.error('Error adding expense:', err.message);
    }
  };

  const handleFileChange = (event) => {
    setExpenseFile(event.target.files[0]);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Legg til ny utgift</h2>
        <form onSubmit={handleAddExpense}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expenseDate">
              Dato
            </label>
            <input
              id="expenseDate"
              type="date"
              value={expenseDate}
              onChange={(e) => setExpenseDate(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expenseAmount">
              Beløp
            </label>
            <input
              id="expenseAmount"
              type="number"
              value={expenseAmount}
              onChange={(e) => setExpenseAmount(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expenseFile">
              Kvittering
            </label>
            <input
              id="expenseFile"
              type="file"
              accept="image/*"
              capture="environment"
              onChange={handleFileChange}
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2 hover:bg-gray-600 transition"
            >
              Avbryt
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
              Legg til
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddExpenseModal;