import React, { useContext } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import MiniInvoiceActions from './MiniInvoiceActions';
import Loader from './Loader';

const CreditNoteTable = ({ creditNotes, onStatusChange, loading }) => {
  const { user } = useContext(UserContext);

  const handleStatusChange = async (creditNoteId, newStatus) => {
    try {
      await axiosInstance.put(`/api/credit-notes/${user.company_id}/${creditNoteId}/status`, { status: newStatus });
      onStatusChange(creditNoteId, newStatus);
    } catch (err) {
      console.error('Failed to update status', err);
    }
  };

  return (
    <table className="min-w-full bg-white rounded-lg shadow overflow-hidden">
      <thead className="bg-dark-blue-new text-white">
        <tr>
          <th className="py-2 px-4 text-left">Kreditnota nummer</th>
          <th className="py-2 px-4 text-left">Kundenavn</th>
          <th className="py-2 px-4 text-right">Total</th>
          <th className="py-2 px-4 text-left">Dato</th>
          <th className="py-2 px-4 text-left">Kunde E-post</th>
          <th className="py-2 px-4 text-left">Kunde Telefon</th>
          <th className="py-2 px-4 text-left">Status</th>
          <th className="py-2 px-4 text-left">Handlinger</th>
        </tr>
      </thead>
      <tbody>
        {loading && <Loader />}
        {creditNotes.map((creditNote) => (
          <tr key={creditNote.id} className="border-b">
            <td className="py-2 px-4"><Link to={`/kreditnotaer/${creditNote?.id}`} className="text-medium-blue-custom hover:text-dark-blue-custom">{creditNote.credit_note_number}</Link></td>
            <td className="py-2 px-4">{creditNote.customer_name}</td>
            <td className="py-2 px-4 text-right">{creditNote.total}</td>
            <td className="py-2 px-4">{new Date(creditNote.credit_note_date).toLocaleDateString('nb-NO')}</td>
            <td className="py-2 px-4">{creditNote.customer_email}</td>
            <td className="py-2 px-4">{creditNote.customer_phone}</td>
            <td className="py-2 px-4">
              <select
                value={creditNote.status}
                onChange={(e) => handleStatusChange(creditNote.id, e.target.value)}
                className="px-3 py-1 border rounded bg-gray-100 text-gray-700"
              >
                <option value="issued">Utstedt</option>
                <option value="cancelled">Kansellert</option>
              </select>
            </td>
            <td className="py-2 px-4">
              <MiniInvoiceActions creditNoteId={creditNote.id} creditNoteNumber={creditNote.credit_note_number} customerEmail={creditNote.customer_email} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CreditNoteTable;