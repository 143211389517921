// src/components/Notification.js
import React, { useContext } from 'react';
import NotificationContext from '../contexts/NotificationContext';
import { FaCheckCircle, FaExclamationCircle, FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';

const Notification = () => {
  const { notifications, removeNotification } = useContext(NotificationContext);

  return (
    <div className="fixed bottom-4 right-4 flex flex-col space-y-4 z-50">
      {notifications.map(notification => (
        <div
          key={notification.id}
          className={`flex items-center p-4 rounded-lg shadow-lg ${getNotificationClass(notification.type)}`}
          onClick={() => removeNotification(notification.id)}
        >
          <div className="mr-3">
            {getNotificationIcon(notification.type)}
          </div>
          <div>
            {notification.message}
          </div>
        </div>
      ))}
    </div>
  );
};

const getNotificationClass = (type) => {
  switch (type) {
    case 'success':
      return 'bg-green-500 text-white';
    case 'error':
      return 'bg-red-500 text-white';
    case 'warning':
      return 'bg-yellow-500 text-black';
    default:
      return 'bg-blue-500 text-white';
  }
};

const getNotificationIcon = (type) => {
  switch (type) {
    case 'success':
      return <FaCheckCircle className="text-white text-xl" />;
    case 'error':
      return <FaExclamationCircle className="text-white text-xl" />;
    case 'warning':
      return <FaExclamationTriangle className="text-black text-xl" />;
    default:
      return <FaInfoCircle className="text-white text-xl" />;
  }
};

export default Notification;