import React from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader';

const ProductTable = ({ products, loading }) => (
  <table className="min-w-full bg-white rounded-lg shadow overflow-hidden">
    <thead className="bg-dark-blue-new text-white">
      <tr>
        <th className="py-2 px-4 text-left">Navn</th>
        <th className="py-2 px-4 text-left">Beskrivelse</th>
        <th className="py-2 px-4 text-right">Pris</th>
        <th className="py-2 px-4 text-center">MVA</th>
      </tr>
    </thead>
    <tbody>
      {loading && <Loader />}
      {products.map((product) => (
        <tr key={product.id} className="border-b">
          <td className="py-2 px-4"><Link to={`/produkter/${product.id}`} className="text-medium-blue-custom hover:text-dark-blue-custom">{product.name}</Link></td>
          <td className="py-2 px-4">{product.description}</td>
          <td className="py-2 px-4 text-right">{product.price}</td>
          <td className="py-2 px-4 text-center">{product.vat_applicable ? 'Ja' : 'Nei'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default ProductTable;