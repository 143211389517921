import React, { useState, useEffect, useContext } from 'react';
import { FaEdit, FaRegSave, FaRegTimesCircle } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

const Company = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [company, setCompany] = useState({
    name: '',
    org_number: '',
    address: '',
    contact_number: '',
    vat_rate: '',
    account_number: '',
    post_number: '',
    post_location: '',
    created_at: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCompany = async () => {
    try {
      const response = await axiosInstance.get(`/api/companies/${user.company_id}`);
      const companyProfile = response.data;
      setCompany({
        name: companyProfile.name,
        org_number: companyProfile.org_number,
        address: companyProfile.address,
        contact_number: companyProfile.contact_number,
        vat_rate: companyProfile.vat_rate,
        account_number: companyProfile.account_number,
        post_number: companyProfile.post_number,
        post_location: companyProfile.post_location,
        created_at: companyProfile.created_at,
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, [user.company_id]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    
    if (name === 'post_number' && value.length === 4) {
      try {
        const response = await axiosInstance.get(`/api/post-number/${value}`);
        setCompany((prevCompany) => ({
          ...prevCompany,
          post_location: response.data.Poststed || '',
        }));
      } catch (err) {
        setCompany((prevCompany) => ({
          ...prevCompany,
          post_location: '',
        }));
      }
    } else if (name === 'post_number' && value.length !== 4) {
      setCompany((prevCompany) => ({
        ...prevCompany,
        post_location: '',
      }));
    }

    if (name === 'account_number') {
      const formattedValue = value.replace(/\s/g, '').replace(/(\d{4})(\d{2})(\d{5})/, '$1 $2 $3');
      setCompany((prevCompany) => ({
        ...prevCompany,
        account_number: formattedValue,
      }));
    } else {
      setCompany((prevCompany) => ({
        ...prevCompany,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put(`/api/companies/${user.company_id}`, company);
      setEditMode(false);
      fetchCompany();
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <Loader />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Bedrift Profil</h2>
      {editMode ? (
        <form onSubmit={handleSubmit} className="space-y-6">
          {Object.keys(company).map((key) => (
            key !== 'created_at' && (
              <div key={key} className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
                  {key.replace('_', ' ').toUpperCase()}
                </label>
                <input
                  id={key}
                  name={key}
                  type={key === 'vat_rate' ? 'number' : 'text'}
                  value={company[key]}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )
          ))}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setEditMode(false)}
              className="flex items-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
            >
              <FaRegTimesCircle className="mr-2" /> Avbryt
            </button>
            <button
              type="submit"
              className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
              <FaRegSave className="mr-2" /> Lagre
            </button>
          </div>
        </form>
      ) : (
        <div className="space-y-4 bg-white p-6 rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <div className="ml-4">
              <h3 className="text-2xl font-bold text-gray-800 mb-2">{company.name}</h3>
              <p className="text-gray-600"><strong>Organisasjonsnummer:</strong> {company.org_number}</p>
              <p className="text-gray-600"><strong>Adresse:</strong> {company.address}</p>
              <p className="text-gray-600"><strong>Kontakt Nummer:</strong> {company.contact_number}</p>
              <p className="text-gray-600"><strong>MVA Sats:</strong> {company.vat_rate}</p>
              <p className="text-gray-600"><strong>Kontonummer:</strong> {company.account_number}</p>
              <p className="text-gray-600"><strong>Postnummer:</strong> {company.post_number}</p>
              <p className="text-gray-600"><strong>Poststed:</strong> {company.post_location}</p>
              <p className="text-gray-600"><strong>Konto opprettet:</strong> {new Date(company.created_at).toLocaleDateString('nb-NO')}</p>
            </div>
          </div>
          <button
            onClick={() => setEditMode(true)}
            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition"
          >
            <FaEdit className="mr-2" /> Rediger Bedrift
          </button>
        </div>
      )}
    </div>
  );
};

export default Company;