import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Pagination from '../../components/Pagination';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import UserTable from '../../components/admin/UserTable';
import NoData from '../../components/NoData';
import Header from '../../components/Header';
import Toolbar from '../../components/Toolbar';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [active, setActive] = useState('');
  const [search, setSearch] = useState('');
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/users', {
        params: { page, limit, active, search }
      });
      setUsers(response.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page, limit, active, search]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <Header text="Brukere" theme="dark" />
      <Toolbar
        showSearch
        searchValue={search}
        onSearchChange={setSearch}
        resultsPerPage={limit}
        onResultsPerPageChange={setLimit}
        showActiveFilter
        active={active}
        onActiveChange={setActive}
        theme="dark"
      />

{!loading && users.length === 0 && <NoData text="Ingen brukere funnet." />}
      {users.length > 0 && (
        <>
          <div className="hidden lg:block">
            <UserTable users={users} loading={loading} />
          </div>
          <Pagination page={page} totalPages={Math.ceil(users.length / limit)} onPageChange={setPage} />
        </>
      )}
    </div>
  );
};

export default ManageUsers;