import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import Pagination from '../../components/Pagination';
import InvoiceTable from '../../components/InvoiceTable';
import InvoiceCards from '../../components/InvoiceCards';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import UserContext from '../../contexts/UserContext';
import Header from '../../components/Header';
import Toolbar from '../../components/Toolbar';

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const [totalInvoices, setTotalInvoices] = useState(0);
  const { user } = useContext(UserContext);

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/api/invoices/${user.company_id}`, {
        params: { page, limit, status, startDate, endDate, search }
      });
      setInvoices(response.data.invoices);
      setTotalInvoices(response.data.total);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, [page, limit, status, startDate, endDate, search]);

  const totalPages = Math.ceil(totalInvoices / limit);

  const handleStatusChange = (invoiceId, newStatus) => {
    setInvoices(invoices.map(invoice =>
      invoice.id === invoiceId ? { ...invoice, status: newStatus } : invoice
    ));
  };

  const invoiceLimit = user.tier === 'Premium' ? 'Ubegrenset' : user.tier === 'Grunnleggende' ? 50 : 5;

  return (
    <div className="p-4">
      <Header text="Fakturaer" linkTo="/fakturaer/ny" />
      <div className="mb-4 text-gray-700">
        {user.company_invoice_count} / {invoiceLimit} fakturaer brukt
      </div>
      <Toolbar
        showSearch
        searchValue={search}
        onSearchChange={setSearch}
        showStatusFilter
        status={status}
        onStatusChange={setStatus}
        showDateFilters
        startDate={startDate}
        onStartDateChange={setStartDate}
        endDate={endDate}
        onEndDateChange={setEndDate}
        resultsPerPage={limit}
        onResultsPerPageChange={setLimit}
      />
      {invoices.length > 0 ? (
        <>
          <div className="hidden lg:block">
            <InvoiceTable invoices={invoices} onStatusChange={handleStatusChange} loading={loading} />
          </div>
          <div className="block lg:hidden">
            <InvoiceCards invoices={invoices} onStatusChange={handleStatusChange} loading={loading} />
          </div>
        </>
      ) : (
        <NoData text="Ingen fakturaer funnet." />
      )}
      <Pagination page={page} totalPages={totalPages} onPageChange={setPage} />
    </div>
  );
};

export default Invoices;