import React from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader';

const ProductCards = ({ products, loading }) => (
  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:hidden">
    {loading && <Loader />}
    {products.map((product) => (
      <div key={product.id} className="bg-white rounded-lg shadow p-4 border border-gray-200">
        <h3 className="text-xl font-bold text-medium-blue-custom hover:text-dark-blue-custom">
          <Link to={`/produkter/${product.id}`}>{product.name}</Link>
        </h3>
        <p><strong>Beskrivelse:</strong> {product.description}</p>
        <p><strong>Pris:</strong> {product.price}</p>
        <p><strong>MVA:</strong> {product.vat_applicable ? 'Ja' : 'Nei'}</p>
      </div>
    ))}
  </div>
);

export default ProductCards;