import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Select, { components } from 'react-select';
import { Tooltip } from 'react-tooltip';
import { FaTimes, FaArrowDown, FaArrowUp } from 'react-icons/fa';

const EditInvoice = () => {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [invoiceProducts, setInvoiceProducts] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(0);
  const [yourReference, setYourReference] = useState('');
  const [ourReference, setOurReference] = useState('');
  const [deliveryReference, setDeliveryReference] = useState('');
  const [reference, setReference] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    const fetchData = async () => {
      try {
        const customersResponse = await axiosInstance.get(`/api/customers/${user.company_id}`);
        const productsResponse = await axiosInstance.get(`/api/products/${user.company_id}`);
        const invoiceResponse = await axiosInstance.get(`/api/invoices/${user.company_id}/${invoiceId}`);

        setCustomers(customersResponse.data);
        setProducts(productsResponse.data.products);
        setInvoice(invoiceResponse.data);
        setInvoiceNumber(invoiceResponse.data.invoice_number);
        setSelectedCustomer({ value: invoiceResponse.data.customer_id, label: invoiceResponse.data.customer_name });
        setInvoiceProducts(invoiceResponse.data.products);
        setYourReference(invoiceResponse.data.your_reference);
        setOurReference(invoiceResponse.data.our_reference);
        setDeliveryReference(invoiceResponse.data.delivery_reference);
        setReference(invoiceResponse.data.reference);
        setInvoiceDate(invoiceResponse.data.invoice_date);
        setDueDate(invoiceResponse.data.due_date);
        setStartDate(invoiceResponse.data.start_date);
        setEndDate(invoiceResponse.data.end_date);
        setLoading(false);
      } catch (err) {
        console.error('Error:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [user.id, invoiceId]);

  const handleAddProduct = (product) => {
    setInvoiceProducts([...invoiceProducts, { ...product, quantity: 1 }]);
  };

  const handleProductChange = (index, key, value) => {
    const newInvoiceProducts = [...invoiceProducts];
    newInvoiceProducts[index][key] = value;
    setInvoiceProducts(newInvoiceProducts);
  };

  const calculateTotals = () => {
    const subtotal = invoiceProducts.reduce((sum, product) => sum + product.price * product.quantity, 0);
    const vatTotal = invoiceProducts.reduce((sum, product) => sum + (product.vat_applicable ? product.price * product.quantity * 0.25 : 0), 0);
    const total = subtotal + vatTotal;
    return { subtotal, vatTotal, total };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { subtotal, vatTotal, total } = calculateTotals();

    try {
      await axiosInstance.put(`/api/invoices/${user.id}/${invoiceId}`, {
        invoice_number: invoiceNumber,
        customer_id: selectedCustomer?.value,
        invoice_date: invoiceDate,
        due_date: dueDate,
        start_date: startDate,
        end_date: endDate,
        status: 'pending',
        your_reference: yourReference,
        our_reference: ourReference,
        delivery_reference: deliveryReference,
        reference,
        subtotal,
        vat_total: vatTotal,
        total,
      });

      await Promise.all(invoiceProducts.map(product =>
        axiosInstance.put(`/api/invoice-products/${user.company_id}/${invoiceId}/${product.product_id}`, {
          invoice_id: invoiceId,
          product_id: product.product_id,
          quantity: product.quantity,
          price: product.price,
        })
      ));

      addNotification('Faktura oppdatert', { type: 'success' });
      navigate(`/fakturaer/${invoiceId}`);
    } catch (err) {
      addNotification('Feil ved oppdatering av faktura', { type: 'error', persist: true });
      setError(err.message);
    }
  };

  const customerOptions = customers.map(customer => ({
    value: customer.id,
    label: customer.name,
  }));

  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <div>
          {props.data.label}
          <br />
          <small>{customers.find(c => c.id === props.data.value).contact_name}</small>
          <br />
          <small>{customers.find(c => c.id === props.data.value).address}</small>
        </div>
      </components.Option>
    );
  };

  const handleRemoveProduct = (index) => {
    const newInvoiceProducts = invoiceProducts.filter((_, i) => i !== index);
    setInvoiceProducts(newInvoiceProducts);
  };

  const handleIncreaseQuantity = (index) => {
    const newInvoiceProducts = [...invoiceProducts];
    newInvoiceProducts[index].quantity += 1;
    setInvoiceProducts(newInvoiceProducts);
  };

  const handleDecreaseQuantity = (index) => {
    const newInvoiceProducts = [...invoiceProducts];
    if (newInvoiceProducts[index].quantity > 1) {
      newInvoiceProducts[index].quantity -= 1;
      setInvoiceProducts(newInvoiceProducts);
    }
  };

  const handleInvoiceDateChange = (date) => {
    setInvoiceDate(date);
    const due = new Date(date);
    due.setDate(due.getDate() + user.due_date_offset);
    setDueDate(due.toISOString().split('T')[0]);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const { subtotal, vatTotal, total } = calculateTotals();

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <Header text="Rediger Faktura (FUNGERER IKKE FOR ØYEBLIKKET)" />
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block text-gray-700">Velg Kunde</label>
          <Select
            options={customerOptions}
            onChange={setSelectedCustomer}
            value={selectedCustomer}
            className="w-full px-3 py-2 border rounded"
            placeholder="Velg Kunde"
            isSearchable
            components={{ Option: CustomOption }}
          />
        </div>
        {selectedCustomer && (
          <div className="mb-6 p-4 border rounded bg-gray-100">
            <h2 className="text-lg font-bold mb-2">{selectedCustomer.label}</h2>
            {customers.filter(customer => customer.id === selectedCustomer.value).map(customer => (
              <div key={customer.id}>
                <p><strong>Kontaktperson:</strong> {customer.contact_name}</p>
                <p><strong>E-post:</strong> {customer.email}</p>
                <p><strong>Telefon:</strong> {customer.phone}</p>
                <p><strong>Organisasjonsnummer:</strong> {customer.org_number}</p>
                <p><strong>Adresse:</strong> {customer.address}</p>
                <p><strong>Postnummer:</strong> {customer.post_number}</p>
                <p><strong>Poststed:</strong> {customer.post_location}</p>
              </div>
            ))}
          </div>
        )}
        <div className="mb-6">
          <label className="block text-gray-700">Fakturanummer</label>
          <input
            type="number"
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">Referanser</label>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <input
              type="text"
              value={yourReference}
              onChange={(e) => setYourReference(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              placeholder="Kundes referanse"
            />
            <input
              type="text"
              value={ourReference}
              onChange={(e) => setOurReference(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              placeholder="Vår referanse"
            />
            <input
              type="text"
              value={deliveryReference}
              onChange={(e) => setDeliveryReference(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              placeholder="Leveringsreferanse"
            />
            <input
              type="text"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              placeholder="Referanse"
            />
          </div>
        </div>
        <div className="mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 mb-1">Fakturadato</label>
              <input
                type="date"
                value={invoiceDate}
                onChange={(e) => handleInvoiceDateChange(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-1">Forfallsdato</label>
              <input
                type="date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-1">Startdato</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-1">Sluttdato</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
          </div>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700">Produkter</label>
          <div className="flex flex-wrap">
            {products.map(product => (
              <button
                key={product.id}
                type="button"
                onClick={() => handleAddProduct(product)}
                className="px-4 py-2 border rounded m-1"
                data-tooltip-id={`product-tooltip-${product.id}`}
              >
                {product.name}
              </button>
            ))}
          </div>
          {products.map(product => (
            <Tooltip id={`product-tooltip-${product.id}`} key={product.id} place="top" effect="solid">
              <div>
                <strong>{product.name}</strong><br />
                {product.description}<br />
                Pris: {product.price} NOK<br />
                MVA: {product.vat_applicable ? 'Ja' : 'Nei'}
              </div>
            </Tooltip>
          ))}
        </div>
        <div className="mb-6">
          <label className="block text-gray-700">Valgte Produkter</label>
          {invoiceProducts.length === 0 && <div className="text-gray-700">Ingen produkter valgt</div>}
          {invoiceProducts.map((product, index) => (
            <div key={index} className="flex flex-col md:flex-row items-center mb-2 space-y-2 md:space-y-0 md:space-x-2">
              <span className="w-full md:w-1/4">{product.product_name}</span>
              <div className="w-full md:w-1/4 flex items-center">
                <button
                  type="button"
                  onClick={() => handleDecreaseQuantity(index)}
                  className="px-3 py-3 bg-red-500 text-white rounded-l hover:bg-red-600 transition focus:outline-none"
                >
                  <FaArrowDown />
                </button>
                <input
                  type="number"
                  value={product.quantity}
                  onChange={(e) => handleProductChange(index, 'quantity', parseInt(e.target.value))}
                  className="no-arrows w-full md:w-12 px-4 py-2 border-t border-b border-gray-300 text-center focus:outline-none"
                  min="1"
                />
                <button
                  type="button"
                  onClick={() => handleIncreaseQuantity(index)}
                  className="px-3 py-3 bg-green-500 text-white rounded-r hover:bg-green-600 transition focus:outline-none"
                >
                  <FaArrowUp />
                </button>
              </div>
              <input
                type="number"
                value={product.price}
                onChange={(e) => handleProductChange(index, 'price', e.target.value)}
                className="w-full md:w-1/4 px-3 py-2 border rounded"
                step="0.01"
              />
              <span className="w-full md:w-1/4 text-right">{(product.quantity * product.price).toFixed(2)} NOK</span>
              <button
                type="button"
                onClick={() => handleRemoveProduct(index)}
                className="ml-2 text-red-500 hover:text-red-700 transition"
              >
                <FaTimes />
              </button>
            </div>
          ))}
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-bold text-gray-700">Totalt</h3>
          <p><strong>Subtotal:</strong> {subtotal.toFixed(2)} NOK</p>
          <p><strong>MVA:</strong> {vatTotal.toFixed(2)} NOK</p>
          <p><strong>Total:</strong> {total.toFixed(2)} NOK</p>
        </div>
        <button type="submit" className="bg-medium-blue-custom text-white px-4 py-2 rounded hover:bg-dark-blue-custom transition">
          Oppdater Faktura
        </button>
      </form>
    </div>
  );
};

export default EditInvoice;