import React, { useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import Header from '../../components/Header';

const AddCustomer = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact_name: '',
    address: '',
    email: '',
    phone: '',
    org_number: '',
    post_number: '',
    post_location: '',
    vat_exempt: false,
    active: true
  });
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });

    if (name === 'post_number') {
      if (value.length === 4) {
        try {
          const response = await axiosInstance.get(`/api/post-number/${value}`);
          setFormData((prevData) => ({
            ...prevData,
            post_location: response.data.Poststed
          }));
        } catch (err) {
          setFormData((prevData) => ({
            ...prevData,
            post_location: ''
          }));
          setError('Postnummer ikke funnet.');
        }
      } else {
        setFormData((prevData) => ({
          ...prevData,
          post_location: ''
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`/api/customers/${user.company_id}`, formData);
      addNotification('Kunde lagt til', {type: 'success'});
      navigate('/kunder'); // Redirect to customers page
    } catch (err) {
      addNotification('Feil ved lagring av kunde', {type: 'error', persist: true});
      setError(err.response?.data?.error || 'Failed to add customer');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <Header text="Legg til kunde" />
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Navn</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Kontaktperson</label>
            <input
              type="text"
              name="contact_name"
              value={formData.contact_name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Adresse</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">E-post</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Telefon</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Organisasjonsnummer</label>
            <input
              type="text"
              name="org_number"
              value={formData.org_number}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Postnummer</label>
            <input
              type="text"
              name="post_number"
              value={formData.post_number}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Poststed</label>
            <input
              type="text"
              name="post_location"
              value={formData.post_location}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              readOnly
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <label className="block text-gray-700">MVA-fritak</label>
            <input
              type="checkbox"
              name="vat_exempt"
              checked={formData.vat_exempt}
              onChange={handleChange}
              className="mr-2"
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <label className="block text-gray-700">Aktiv</label>
            <input
              type="checkbox"
              name="active"
              checked={formData.active}
              onChange={handleChange}
              className="mr-2"
            />
          </div>
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="bg-medium-blue-custom text-white px-4 py-2 rounded hover:bg-dark-blue-custom transition"
          >
            Legg til kunde
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCustomer;