import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OutstandingPayments = () => {
  const [invoices, setInvoices] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/${user.id}/outstanding-payments`);
        setInvoices(response.data);
      } catch (err) {
        console.error('Feil ved henting av utestående betalinger:', err);
      }
    };

    fetchData();
  }, [user.id]);

  const calculateDaysOverdue = (dueDate) => {
    const currentDate = new Date();
    const due = new Date(dueDate);
    const timeDiff = currentDate - due;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };

  const data = {
    labels: invoices.map((invoice) => `Faktura ${invoice.invoice_number}`),
    datasets: [
      {
        label: 'Dager forfalt',
        data: invoices.map((invoice) => calculateDaysOverdue(invoice.due_date)),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Totalbeløp (NOK)',
        data: invoices.map((invoice) => invoice.total),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Utestående Betalinger',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-bold mb-4">Utestående Betalinger</h3>
      <Bar data={data} options={options} />
      <ul className="mt-4">
        {invoices.map((invoice) => (
          <li key={invoice.id} className="mb-4 border-b pb-2">
            <div className="text-gray-700"><strong>Fakturanummer:</strong> {invoice.invoice_number}</div>
            <div className="text-gray-700"><strong>Kunde:</strong> {invoice.customer_id}</div>
            <div className="text-gray-700"><strong>Total:</strong> {invoice.total} NOK</div>
            <div className="text-gray-700"><strong>Dager forfalt:</strong> {calculateDaysOverdue(invoice.due_date)}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OutstandingPayments;