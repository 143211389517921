// src/components/admin/Card.js

import React from 'react';
import { FaUser, FaFileInvoice, FaDollarSign, FaShieldAlt, FaMoneyBillWave } from 'react-icons/fa';

const icons = {
  users: <FaUser className="text-4xl text-white opacity-75" />,
  invoices: <FaFileInvoice className="text-4xl text-white opacity-75" />,
  revenue: <FaDollarSign className="text-4xl text-white opacity-75" />,
  expenses: <FaMoneyBillWave className="text-4xl text-white opacity-75" />,
  licenses: <FaShieldAlt className="text-4xl text-white opacity-75" />,
};

const Card = ({ title, value, icon }) => {
  return (
    <div className="bg-gradient-to-r from-gray-700 via-gray-900 to-black p-6 rounded-lg shadow-lg flex items-center">
      <div className="mr-4">
        {icons[icon]}
      </div>
      <div>
        <h2 className="text-lg font-bold mb-2 text-gray-300">{title}</h2>
        <p className="text-3xl font-semibold text-white">{value}</p>
      </div>
    </div>
  );
};

export default Card;