import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import Loader from '../../components/Loader';
import CustomerInvoiceTable from '../../components/CustomerInvoiceTable';
import CustomerInvoiceCards from '../../components/CustomerInvoiceCards';
import { FaUser, FaEnvelope, FaPhone, FaBuilding, FaMapPin } from 'react-icons/fa';
import Subheader from '../../components/Subheader';

const CustomerProfile = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const customerResponse = await axiosInstance.get(`/api/customers/${user.company_id}/${customerId}`);
        const invoicesResponse = await axiosInstance.get(`/api/invoices/${user.id}/${customerId}/invoices`);
        setCustomer(customerResponse.data);
        setInvoices(invoicesResponse.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, [customerId, user.id]);

  const handleStatusChange = (invoiceId, newStatus) => {
    setInvoices(invoices.map(invoice =>
      invoice.id === invoiceId ? { ...invoice, status: newStatus } : invoice
    ));
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!customer) {
    return <div>No customer found.</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-dark-blue-custom">Kundeprofil</h1>
      </div>
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="mb-6">
          <h2 className="text-xl font-bold text-dark-blue-custom mb-4">Kundedetaljer</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center text-gray-700">
              <FaUser className="text-medium-blue-custom mr-3" />
              <p><strong>Navn:</strong> {customer.name}</p>
            </div>
            <div className="flex items-center text-gray-700">
              <FaUser className="text-medium-blue-custom mr-3" />
              <p><strong>Kontaktperson:</strong> {customer.contact_name}</p>
            </div>
            <div className="flex items-center text-gray-700">
              <FaBuilding className="text-medium-blue-custom mr-3" />
              <p><strong>Adresse:</strong> {customer.address}</p>
            </div>
            <div className="flex items-center text-gray-700">
              <FaEnvelope className="text-medium-blue-custom mr-3" />
              <p><strong>E-post:</strong> {customer.email}</p>
            </div>
            <div className="flex items-center text-gray-700">
              <FaPhone className="text-medium-blue-custom mr-3" />
              <p><strong>Telefon:</strong> {customer.phone}</p>
            </div>
            <div className="flex items-center text-gray-700">
              <FaBuilding className="text-medium-blue-custom mr-3" />
              <p><strong>Organisasjonsnummer:</strong> {customer.org_number}</p>
            </div>
            <div className="flex items-center text-gray-700">
              <FaMapPin className="text-medium-blue-custom mr-3" />
              <p><strong>Postnummer:</strong> {customer.post_number}</p>
            </div>
            <div className="flex items-center text-gray-700">
              <FaMapPin className="text-medium-blue-custom mr-3" />
              <p><strong>Poststed:</strong> {customer.post_location}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8">
        <Subheader text="Fakturaer" linkTo={'/fakturaer/ny/kunde/' + customer.id} />
        {invoices.length > 0 ? (
          <>
          <div className="hidden lg:block">
          <CustomerInvoiceTable invoices={invoices} onStatusChange={handleStatusChange} />
        </div>
        <div className="lg:hidden">
          <CustomerInvoiceCards invoices={invoices} onStatusChange={handleStatusChange} />
        </div>
          </>
        ) : (
          <div className="text-center text-gray-500">Ingen fakturaer funnet.</div>
        )}
      </div>
    </div>
  );
};

export default CustomerProfile;