// src/components/Tabs.js

import React, { useState, useEffect } from 'react';

const Tabs = ({ tabs, initialTab }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].title);

  useEffect(() => {
    if (initialTab) {
      const tab = tabs.find((tab) => tab.title.toLowerCase() === initialTab.toLowerCase());
      if (tab) {
        setActiveTab(tab.title);
      }
    }
  }, [initialTab, tabs]);

  return (
    <div>
      <div className="flex border-b">
        {tabs.map((tab) => (
          <button
            key={tab.title}
            className={`px-4 py-2 -mb-px font-semibold text-gray-700 border-b-2 transition ${
              activeTab === tab.title
                ? 'border-blue-500 text-blue-500'
                : 'border-transparent hover:text-blue-500 hover:border-blue-500'
            }`}
            onClick={() => setActiveTab(tab.title)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="p-4">
        {tabs.map((tab) => tab.title === activeTab && <div key={tab.title}>{tab.content}</div>)}
      </div>
    </div>
  );
};

export default Tabs;