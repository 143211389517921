// src/components/InvoiceCards.js
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import UserContext from '../contexts/UserContext';
import { FaFileInvoiceDollar, FaRegCalendarAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import Loader from './Loader';

const InvoiceCards = ({ invoices, onStatusChange, loading }) => {
  const { user } = useContext(UserContext);
  const handleStatusChange = async (invoiceId, newStatus) => {
    try {
      await axiosInstance.put(`/api/invoices/${user.company_id}/${invoiceId}/status`, { status: newStatus });
      onStatusChange(invoiceId, newStatus);
    } catch (err) {
      console.error('Failed to update status', err);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
      {loading && <Loader />}
      {invoices.map((invoice) => (
        <div key={invoice.id} className="bg-white p-4 rounded-lg shadow-md">
          <Link to={`/fakturaer/${invoice.id}`} className="block mb-2 text-xl font-bold text-medium-blue-custom hover:text-dark-blue-custom">
            <FaFileInvoiceDollar className="inline-block mr-2" /> {invoice.invoice_number}
          </Link>
          <p className="text-gray-700"><FaRegCalendarAlt className="inline-block mr-2" /> {new Date(invoice.invoice_date).toLocaleDateString('nb-NO')}</p>
          <p className="text-gray-700"><FaEnvelope className="inline-block mr-2" /> {invoice.customer_email}</p>
          <p className="text-gray-700"><FaPhone className="inline-block mr-2" /> {invoice.customer_phone}</p>
          <p className="text-gray-700"><strong>Total:</strong> {invoice.total}</p>
          <div className="mt-2">
            <label className="block text-gray-700 text-sm font-bold mb-1" htmlFor="status">
              Status
            </label>
            <select
              id="status"
              value={invoice.status}
              onChange={(e) => handleStatusChange(invoice.id, e.target.value)}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="paid">Betalt</option>
              <option value="pending">Ubetalt</option>
              <option value="overdue">Forfalt</option>
              <option value="cancelled">Kansellert</option>
            </select>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InvoiceCards;