import React, { useContext } from "react";
import axiosInstance from "../../utils/axiosInstance";
import ExpenseCard from "./ExpenseCard";
import NoData from "../../components/NoData";
import UserContext from "../../contexts/UserContext";

const ExpenseList = ({ expenses, baseApiUrl, fetchPdf, openModal, fetchExpenses }) => {
  const { user } = useContext(UserContext);

  const handleDelete = async (expenseId) => {
    try {
      await axiosInstance.delete(`/api/expenses/${user.company_id}/${expenseId}`);
      fetchExpenses(); // Refresh the expense list
    } catch (err) {
      console.error("Failed to delete expense:", err);
    }
  };

  if (expenses.length === 0) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <NoData text="Ingen utgifter funnet i denne kategorien." />
      </div>
    );
  }

  return (
    <div className="flex flex-wrap -mx-2 sm:-mx-4">
      {expenses.map((expense) => (
        <ExpenseCard
          key={expense.id}
          expense={expense}
          baseApiUrl={baseApiUrl}
          fetchPdf={fetchPdf}
          openModal={openModal}
          onDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default ExpenseList;