import React, { useContext } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import UserContext from '../contexts/UserContext';

const CustomerInvoiceCards = ({ invoices, onStatusChange }) => {
  const { user } = useContext(UserContext);

  const handleStatusChange = async (invoiceId, newStatus) => {
    try {
      await axiosInstance.put(`/api/invoices/${user.company_id}/${invoiceId}/status`, { status: newStatus });
      onStatusChange(invoiceId, newStatus);
    } catch (err) {
      console.error('Failed to update status', err);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:hidden">
      {invoices.map((invoice) => (
        <div key={invoice.id} className="bg-white rounded-lg shadow p-4 border border-gray-200">
          <h3 className="text-xl font-bold text-medium-blue-custom hover:text-dark-blue-custom">
            <Link to={`/fakturaer/${invoice.id}`}>{invoice.invoice_number}</Link>
          </h3>
          <p><strong>Total:</strong> {invoice.total}</p>
          <p><strong>Dato:</strong> {new Date(invoice.invoice_date).toLocaleDateString('nb-NO')}</p>
          <p><strong>Status:</strong></p>
          <select
            value={invoice.status}
            onChange={(e) => handleStatusChange(invoice.id, e.target.value)}
            className="px-3 py-1 border rounded bg-gray-100 text-gray-700 mt-2"
          >
            <option value="paid">Betalt</option>
            <option value="pending">Ubetalt</option>
            <option value="overdue">Forfalt</option>
            <option value="cancelled">Kansellert</option>
          </select>
        </div>
      ))}
    </div>
  );
};

export default CustomerInvoiceCards;