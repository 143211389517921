import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';
import LicenseInput from './LicenseInput';
import LicenseDetails from './LicenseDetails';
import FeatureList from './FeatureList';
import Subheader from '../Subheader';

const License = () => {
  const [licenseParts, setLicenseParts] = useState(['', '', '', '']);
  const [license, setLicense] = useState(null);
  const [features, setFeatures] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchLicenseData = async () => {
      try {
        const response = await axiosInstance.get(`/api/companies/${user.company_id}/license`);
        setLicense(response.data.license);
        setFeatures(response.data.features);
      } catch (error) {
        console.error('Error fetching license data:', error);
      }
    };

    fetchLicenseData();
  }, []);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (value.length <= 5) {
      const newLicenseParts = [...licenseParts];
      newLicenseParts[index] = value.toUpperCase();
      setLicenseParts(newLicenseParts);

      if (value.length === 5 && index < 3) {
        document.getElementById(`part-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (pasteData.length === 20) {
      const newLicenseParts = [
        pasteData.substring(0, 5),
        pasteData.substring(5, 10),
        pasteData.substring(10, 15),
        pasteData.substring(15, 20),
      ];
      setLicenseParts(newLicenseParts);
      document.getElementById('part-3').focus();
    }
  };

  const handleActivateLicense = () => {
    const licenseKey = licenseParts.join('-');
    // Activation logic here
    console.log('Activating license with key:', licenseKey);
  };

  return (
    <div className="max-w-full mx-auto p-6 bg-white shadow-md rounded-lg">
      <Subheader text="Lisens" subtitle="I Arbeid" />
      {license ? (
        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <div className="md:w-1/3">
            <LicenseDetails license={license} />
          </div>
          <div className="md:w-2/3">
            <FeatureList features={features} />
          </div>
        </div>
      ) : (
        <LicenseInput
          licenseParts={licenseParts}
          handleInputChange={handleInputChange}
          handlePaste={handlePaste}
          handleActivateLicense={handleActivateLicense}
        />
      )}
    </div>
  );
};

export default License;