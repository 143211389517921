import React, { useContext, useState } from 'react';
import { FaUserShield, FaChartBar, FaUsersCog, FaBars, FaSignOutAlt, FaHome } from 'react-icons/fa'; // Import FaHome
import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext';

const AdminSidebarLayout = ({ children }) => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  if (!user || !user.is_admin) {
    return <div>Unauthorized</div>;
  }

  const adminMenuItemStyle = 'inline-block mr-2';

  const adminMenuItems = [
    { to: '/admin', icon: <FaChartBar className={adminMenuItemStyle} />, label: 'Dashboard' },
    { to: '/admin/users', icon: <FaUsersCog className={adminMenuItemStyle} />, label: 'Users' },
    { to: '/admin/licenses', icon: <FaUserShield className={adminMenuItemStyle} />, label: 'Licenses' },
  ];

  const linkClasses = (path) => (
    `flex items-center p-2 my-2 transition-colors duration-200 rounded-lg hover:bg-dark-hover hover:text-dark-text ${location.pathname === path ? 'bg-dark-hover text-dark-text' : 'text-dark-text'}`
  );

  const handleLogout = () => {
    navigate('/logg-ut');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLinkClick = () => {
    if (isSidebarOpen) {
      setIsSidebarOpen(false);
    }
  };

  return (
    <div className="flex min-h-screen bg-dark-bg">
      <div className={`fixed inset-0 bg-black opacity-50 z-40 transition-opacity ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
      <aside className={`fixed inset-0 w-full lg:w-64 bg-dark-sidebar p-4 flex flex-col transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 transition-transform duration-300 ease-in-out shadow-lg z-50`}>
        <div className="mb-6 flex justify-center">
          <img src="/images/site-images/logo_fattern_faktura.png" alt="Fattern Faktura Logo" className="h-16" />
        </div>
        <ul className="flex-grow overflow-auto">
          {adminMenuItems.map((item) => (
            <li key={item.to}>
              <Link to={item.to} className={linkClasses(item.to)} onClick={handleLinkClick}>
                {item.icon} {item.label}
              </Link>
            </li>
          ))}
          <li>
            <Link to="/dashboard" className={linkClasses('/dashboard')} onClick={handleLinkClick}>
              <FaHome className={adminMenuItemStyle} /> Main Dashboard
            </Link>
          </li>
        </ul>
        <div className="text-center text-dark-text font-bold mb-4 p-4 bg-dark-hover rounded-lg">
          Admin Panel
        </div>
        <div className="text-center mb-4">
          <button
            onClick={handleLogout}
            className="flex items-center justify-center w-full text-dark-text hover:text-dark-text hover:bg-dark-hover transition-colors duration-200 p-2"
          >
            <FaSignOutAlt className="inline-block mr-2" /> Logg ut
          </button>
        </div>
        <footer className="text-dark-text mt-4">
          © {new Date().getFullYear()} Fattern Faktura
        </footer>
      </aside>
      <main className="flex-1 p-4 bg-dark-bg lg:ml-64">
        <div className="lg:hidden fixed top-0 right-0 p-4 z-50">
          <button onClick={toggleSidebar} className="text-dark-text focus:outline-none">
            <FaBars className="text-2xl" />
          </button>
        </div>
        {children}
      </main>
    </div>
  );
};

export default AdminSidebarLayout;