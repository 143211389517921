import React from 'react';
import RecentInvoices from './widgets/RecentInvoices';
import OutstandingPayments from './widgets/OutstandingPayments';
import CustomerOverview from './widgets/CustomerOverview';
import ExpenseSummary from './widgets/ExpenseSummary';
import ProductList from './widgets/ProductList';
import RevenueVsExpenses from './widgets/RevenueVsExpenses';
import SalesByProduct from './widgets/SalesByProduct';
import VatSummary from './widgets/VatSummary';

const componentsList = [
    {
      name: 'Siste Fakturaer',
      description: 'Viser en liste eller oppsummering av de siste fakturaene.',
      preview: '/path/to/recent-invoices.jpg',
      component: RecentInvoices,
    },
    {
      name: 'Utestående Betalinger',
      description: 'Viser fakturaer som er forfalt eller ubetalt.',
      preview: '/path/to/outstanding-payments.jpg',
      component: OutstandingPayments,
    },
    {
      name: 'Kundeoversikt',
      description: 'Viser en liste over nylig registrerte kunder.',
      preview: '/path/to/customer-overview.jpg',
      component: CustomerOverview,
    },
    {
      name: 'Utgiftsoversikt',
      description: 'Viser en oversikt over utgifter etter kategori.',
      preview: '/path/to/expense-summary.jpg',
      component: ExpenseSummary,
    },
    {
      name: 'Produktliste',
      description: 'Viser en liste over produkter.',
      preview: '/path/to/product-list.jpg',
      component: ProductList,
    },
    {
      name: 'Inntekter vs Utgifter',
      description: 'Viser en sammenligning av inntekter og utgifter.',
      preview: '/path/to/revenue-vs-expenses.jpg',
      component: RevenueVsExpenses,
    },
    {
      name: 'Salg per Produkt',
      description: 'Viser totale salg per produkt.',
      preview: '/path/to/sales-by-product.jpg',
      component: SalesByProduct,
    },
    {
      name: 'MVA-oversikt',
      description: 'Viser innkrevd og betalt MVA.',
      preview: '/path/to/vat-summary.jpg',
      component: VatSummary,
    },
  ];

const ComponentModal = ({ isOpen, onClose, onAddComponent }) => {
  return (
    <div className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div className={`fixed inset-y-0 right-0 bg-white w-96 p-4 overflow-y-auto transition-transform transform ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Legg Til Widget</h2>
          <button onClick={onClose} className="text-red-500 font-bold">Lukk</button>
        </div>
        <div className="space-y-4">
          {componentsList.map((component) => (
            <div key={component.name} className="border rounded-lg overflow-hidden shadow hover:shadow-lg transition-shadow duration-200">
              <div className="p-2">
                <h3 className="text-lg font-bold">{component.name}</h3>
                <p>{component.description}</p>
                <button
                  onClick={() => onAddComponent(component.name)}
                  className="mt-2 px-4 py-2 bg-medium-blue-custom text-white rounded"
                >
                  Legg Til {component.name}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComponentModal;