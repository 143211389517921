import React, { useEffect, useState, useContext } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserContext from '../../contexts/UserContext';

const VatSummary = () => {
  const [vat, setVat] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/stats/${user.id}/vat-summary`);
        setVat(response.data);
      } catch (err) {
        console.error('Feil ved henting av MVA-oversikt:', err);
      }
    };

    fetchData();
  }, [user.id]);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-lg font-bold mb-4">MVA-oversikt</h3>
      <div className="text-gray-700"><strong>MVA innkrevd:</strong> {vat.vat_collected} NOK</div>
      <div className="text-gray-700"><strong>MVA betalt:</strong> {vat.vat_paid} NOK</div>
    </div>
  );
};

export default VatSummary;