import React from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';

const Subheader = ({ text, subtitle, linkTo }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
      <div>
        <div className="flex items-center space-x-2">
          <h2 className="text-2xl font-bold text-dark-blue-custom">{text}</h2>
          {linkTo && (
            <Link to={linkTo} className="text-medium-blue-custom hover:text-dark-blue-custom transition">
              <div className="p-2 bg-medium-blue-custom text-white rounded-full hover:bg-dark-blue-custom">
                <FaPlus className="text-sm" />
              </div>
            </Link>
          )}
        </div>
        {subtitle && <p className="text-sm text-gray-500 mt-1">{subtitle}</p>}
      </div>
    </div>
  );
};

export default Subheader;